<div class="modal-overlay"></div>
<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9">
  <div class="section">
    <div class="container">
      <app-image-cropper
      #imageCopperElement
      [isAllowedImage]="isAllowedImage"
      [imageChangedEvent]="imageChangedEvent"
      [imageURL]="imageURL"
      [maintainAspectRatio]="maintainAspectRatio"
      [containWithinAspectRatio]="containWithinAspectRatio"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="resizeToWidth"
      [cropperMinWidth]="cropperMinWidth"
      [onlyScaleDown]="onlyScaleDown"
      [roundCropper]="roundCropper"
      [cropper]="cropper"
      [canvasRotation]="canvasRotation"
      [transform]="transform"
      [alignImage]="alignImage"
      (cropImageEvent)="onCropImage($event)"
    ></app-image-cropper>
      <form [formGroup]="addMedicalTestForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-8">
            <div class="sigma_form style-7">
              <div class="form-block">
                <h4>Test Information:</h4>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Name</label>
                      <i class="fal fa-briefcase-medical"></i>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Blood Typing"
                        formControlName="name"
                      >
                      <div *ngIf="submitted && f.name.errors" class="is-invalid">
                        <div *ngIf="f.name.errors.required">Test Name required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Selling Cost</label>
                      <i class="fal fa-money-bill"></i>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="30000"
                        formControlName="price"
                      >
                      <div *ngIf="submitted && f.price.errors" class="is-invalid">
                        <div *ngIf="f.price.errors.required">Selling Cost required</div>
                        <div *ngIf="f.price.errors.pattern">Please enter a valid number</div>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="isSuperAdmin" class="col-lg-6">
                    <div class="form-group">
                      <label>Partner Cost</label>
                      <i class="fal fa-money-bill"></i>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="20000"
                        formControlName="partnerPrice"
                      >
                      <div *ngIf="submitted && f.partnerPrice.errors" class="is-invalid">
                        <div *ngIf="f.partnerPrice.errors.required">Partner Cost required</div>
                        <div *ngIf="f.partnerPrice.errors.pattern">Please enter a valid number</div>

                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Category</label>
                      <select
                        class="form-control"
                        placeholder="Category"
                        formControlName="category"
                      >
                        <option value="" disabled selected>Category</option>
                        <option  *ngFor="let item of categories" [value]="item.title">{{ item.title }}</option>
                      </select>
                      <div *ngIf="submitted && f.category?.errors?.required" class="is-invalid">
                        Category required
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Duration</label>
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="2"
                          formControlName="time"
                        >
                        <select
                          class="form-control"
                          placeholder="Hours"
                          formControlName="duration"
                        >
                        <option value="" disabled selected>Time</option>
                          <option value="Minutes">Minutes</option>
                          <option value="Hours">Hours</option>
                          <option value="Days">Days</option>
                        </select>
                      </div>
                      <div *ngIf="submitted && (f.time.errors || f.duration.errors)" class="is-invalid">
                        <div *ngIf="f.time.errors?.required || f.duration.errors?.required">Time and Duration required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Upload Medical Test Images</label>
                      <div class="upload-photo">
                        <div class="d-table">
                          <div class="d-table-cell">
                            <div class="form-group">
                              <i *ngIf="!croppedImages.length" class="icofont-photobucket"></i>
                              <input
                                *ngIf="croppedImages.length < 5"
                                class="form-control-file"
                                type="file"
                                accept="image/png, image/jpeg, image/jpg"
                                (change)="onImageChange($event)"
                              >
                            </div>
                            <div *ngFor="let croppedImage of croppedImages; let i = index" class="image-container mb-2">
                              <img
                                [src]="croppedImage"
                                alt="Preview"
                                class="img-thumbnail mr-2"
                                style="max-height: 100px;"
                              />
                              <button
                                type="button"
                                class="btn btn-sm btn-danger delete"
                                (click)="onRemoveImge(i, false)"
                              >Remove</button>
                            </div>
                            <ng-container *ngIf="medicalTest && medicalTest.images && medicalTest.images.length > 0;">
                              <div *ngFor="let image of medicalTest.images; let i = index" class="image-container mb-2">
                                <img [src]="image" alt="Medical Test Image" class="img-thumbnail mr-2" style="max-height: 100px;">
                                <button
                                  type="button"
                                  class="btn btn-sm btn-danger delete"
                                  (click)="onRemoveImge(i, true)"
                                >Remove</button>
                              </div>
                            </ng-container>
                            <div *ngIf="submitted && !croppedImages.length" class="is-invalid">
                              At least one photo is required
                            </div>
                            <div *ngIf="croppedImages.length === 5" class="mt-3">
                              <p class="text-danger">You have reached the maximum limit of 5 images.</p>
                            </div>
                            <div *ngIf="!isAllowedImage" class="is-invalid">
                              Width & height max 600px X 400px and not greater than 5 MB
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Description</label>
                      <i class="fal fa-comment-medical"></i>
                      <textarea
                        rows="5"
                        class="form-control"
                        placeholder="Enter test description"
                        formControlName="description"
                      >
                      </textarea>
                      <div *ngIf="submitted && f.description.errors" class="is-invalid">
                        <div *ngIf="f.description.errors.required">Description required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="sidebar style-10 mt-5 mt-lg-0">
              <!-- Test Widget -->
              <div class="widget widget-booking">
                <h5 class="widget-title">Test Summary</h5>
                <ul>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Test Name</span>
                    <span>{{f.name.value}}</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Selling Price</span>
                    <span>{{f.price.value}}</span>
                  </li>
                  <li *ngIf="isSuperAdmin" class="d-flex align-items-center justify-content-between">
                    <span>Partner Price</span>
                    <span>{{f.partnerPrice.value}}</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Category</span>
                    <span>{{f.category.value}}</span>
                  </li>
                  <li class="d-flex align-items-center justify-content-between">
                    <span>Duration</span>
                    <span>{{f.time.value + ' ' + f.duration.value}}</span>
                  </li>
                </ul>
                <hr>
                <ul>
                  <li class="d-flex align-items-center justify-content-between">
                    <button
                      type="submit"
                      [disabled]="addMedicalTestForm.invalid "
                      class="sigma_btn btn-block btn-sm mt-4">
                      Submit
                      <i class="fal fa-arrow-right ml-3"></i>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
