export interface FilterDetails {
  title: string;
  icon: string;
}

export interface NavigationDetails {
  link: string;
  linkText: string;
}

export interface OrderStatus {
  status: string;
  description: string;
}

export interface Counter {
  icon: string;
  title: string;
  value: number;
}

export interface Partner {
  name: string;
  image: string;
}

export interface OtherServices {
  title: string;
  images?: string[];
  introduction?: string;
  description: string;
  icon?: string;
}

export interface ContentItem {
  title: string;
  text: string;
}

export interface FAQCategory {
  id: number;
  title: string;
  items: ContentItem[];
}

export interface CommonData extends ContentItem {
  icon: string;
}
