import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  CartService,
  DataService,
  DocumentService
} from '../../services';
import { FilterDetails, MedicalTest, User, Search } from '../../models';

@Component({
  selector: 'app-all-medical-tests',
  templateUrl: './medical-tests.component.html',
  styleUrls: ['./medical-tests.component.scss']
})

export class MedicalTestsComponent implements OnInit{
  page: number = 1;
  filterIcons: FilterDetails[] = [];
  medicalTests: MedicalTest[] = [];
  user: User;
  cartItems: MedicalTest[] = [];
  categoryNames: string[] = [];
  isSelectedMedicalTest: boolean = false;
  isResponsive: boolean = true;
  stateOfFilter: boolean[] = [];
  slickCarouselConfig: any;

  constructor(
    private router: Router,
    private cartService: CartService,
    private dataService: DataService,
    private documentService: DocumentService,
  ) {}

  ngOnInit() {
    this.slickCarouselConfig = {
      ...this.documentService.getslickCarouselConfig(),
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToScroll: 1
    };
    this.populateMedicalTests();
    this.filterIcons = this.dataService.getFilterDetails();
    this.cartService.getCartMedicalTests().subscribe(cartItems => this.cartItems = cartItems);
    this.dataService.getSearchCriteria().subscribe(searchCriteria => {
      if (searchCriteria) {
        this.filterMedicalTests(searchCriteria as Search); 
      }
    });
  }

  populateMedicalTests() {
    this.stateOfFilter = [];
    this.dataService.getAllMedicalTests().subscribe(
      medicalTests => {
        this.medicalTests = medicalTests;
        if (this.medicalTests.length === 0) {
          this.router.navigateByUrl('/');
        }
    });
  }

  addToCart(medicalTest: MedicalTest) {
    this.dataService.addToCart(medicalTest, this.cartItems);
  }

  filterMedicalTests(searchCriteria: Search, index?: number) {
    this.dataService.filterMedicalTests(searchCriteria, index)
    .subscribe(response => this.medicalTests = response);
  }

  goToMedicalTestDetails(medicalTest: MedicalTest) {
    this.dataService.saveDataToView(medicalTest);
    this.router.navigate(['medical-test-details']);
  }
}
