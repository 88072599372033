import { Component, OnInit } from '@angular/core';
import { Partner } from '../../models';
import { DataService } from '../../services';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  partners: Partner[];
  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.partners = this.dataService.getPartners();
  }

}
