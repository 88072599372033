<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9 service-details">
  <div class="section sigma_post-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="payment-notification">
            <div>
              <div class="container right">
                <div class="content">
                  <h4 class="order-title">Payment Failed</h4>
                  <p>Payment was unsuccessful</p>
                  <p class="text-muted"> <a routerLink="/cart"
                    class="secondary-color font-weight-bold ml-1">Back To Cart</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

