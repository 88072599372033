import { Injectable } from '@angular/core';
import { ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { ImageCropperComponent } from '../components/image-cropper/image-cropper.component';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  loading: boolean = false;
  imageChangedEvent: any;
  isAllowedImage: boolean;
  uploadedImage: File;
  croppedImages: string[];

  constructor() { }

  onImageChange(event: any, imageCopperElement: ImageCropperComponent, isAllowedImage: boolean, callback?: any): void {
    event.preventDefault();
    this.loading = true;
    this.imageChangedEvent = event;
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type === 'image/jpeg' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg') {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const img = new Image();
          img.src = reader.result as string;
          img.onload = () => {
            if ((img.naturalWidth <= 600) && (img.naturalHeight <= 400) && file.size < 5000000) {
              callback(true, this.imageChangedEvent, img.src);
            } else {
              callback(false, this.imageChangedEvent);
              imageCopperElement.openModal();
            }
          };
        };
      }
      this.uploadedImage = event.target.files[0];
    }
  }

  onCropImage(croppedImage: string, callback: any) {
    this.uploadedImage = new File(
      [base64ToFile(croppedImage)],
      this.uploadedImage.name,
      { lastModified: this.uploadedImage.lastModified, type: this.uploadedImage.type });
    callback(this.uploadedImage);
  }

  getslickCarouselConfig() {
    return {
      infinite: true,
      lazyLoad: 'ondemand',
      slidesToShow: 5,
      slidesToScroll: 3,
      variableWidth: true,
      arrows: true,
      dots: false,
      speed: 1000,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            variableWidth: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            variableWidth: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: true,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2
          }
        }
      ]
    };
  }

}
