import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../helper/helper.service';
import { CartService, DataService, AuthenticationService } from '../../services';
import { MedicalTest, NavigationDetails, User } from '../../models';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends HelperService implements OnInit {
  navigation: NavigationDetails[];
  cartItems: MedicalTest[] = [];
  isLoggedIn: Observable<boolean>;
  isAdmin: Observable<boolean>;
  user: User;
  navMethod: boolean = false; 

  constructor(
    public router: Router,
    private cartService: CartService,
    private dataService: DataService,
    private authenticationService: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.isAdmin = this.authenticationService.isAdminLoggedIn();
    this.navigation = this.dataService.getNavigationDetails();
    this.cartService.getCartMedicalTests()
      .subscribe(cartTests => this.cartItems = cartTests);
  }

  toggleNav(): void {
    this.navMethod = !this.navMethod; 
  }

  goToCart() {
    if (this.cartItems.length === 0) {
      Swal.fire({
        title: '',
        html: '<h4>Your Cart</h4>There are no items in your cart',
        icon: 'info',
        confirmButtonColor: 'var(--thm-base)',
        customClass: {
          icon: 'sweet-alert-icon-class'
        },
        showClass: {
          popup: 'sweet-alert-slow-animation-show'
        },
        hideClass: {
          popup: 'sweet-alert-slow-animation-hide'
        }
      });
    } else {
      this.router.navigateByUrl('cart');
    }
  }

  onLogout() {
    this.authenticationService.logout();
  }
}
