import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import { Router } from '@angular/router';
import { MedicalTest, Order, OrderStatus } from '../../models';
import { 
  DataService,
  OrderService,
  UserProfileService 
} from '../../services';
import { first, mergeMap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss']
})
export class EditOrderComponent implements OnInit {
  editOrderForm: FormGroup;
  submitted = false;
  medicalTests: MedicalTest[] = [];
  order: Order;
  orderStatus: OrderStatus[];
  availableStatuses: OrderStatus[];

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private orderService: OrderService,
    private userProfileService: UserProfileService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.orderStatus = this.dataService.getOrderStatus();
    this.editOrderForm = this.formBuilder.group({
      name: [''],
      email: [''],
      telephone: [''],
      birthDate: [''],
      availability: [''],
      location: [''],
      note: [''],
      status: ['', Validators.required]
    });
    this.dataService.getOrderToEdit().pipe(
      mergeMap(order => {
        if (!order) {
          this.router.navigate(['admin']);
        } else {
          this.order = order as Order;
          this.medicalTests = this.order.medical_tests as MedicalTest[];
        }      
        return this.userProfileService.getSingleUser(this.order?.user_id as number);
      })).subscribe(user => {
        if (user) {
          this.f.name.setValue(user.firstname + ' ' + user.lastname);
          this.f.email.setValue(user.email);
          this.f.telephone.setValue(this.order.telephone);
          this.f.birthDate.setValue(user.birth_date);
          this.f.availability.setValue(this.order.availability);
          this.f.location.setValue(this.order.location);
          this.f.note.setValue(this.order.note);
          this.f.status.setValue(this.order.status);
          this.setAvailableStatusOptions();
        }
    });
  }

  get f() {
    return this.editOrderForm.controls;
  }

  setAvailableStatusOptions(): void {
    const currentStatusIndex = this.orderStatus.findIndex(status => 
      status.status === this.order.status);
    if (currentStatusIndex !== -1) {
      this.availableStatuses = this.orderStatus.slice(currentStatusIndex, currentStatusIndex + 2);
    }
  }

  getTotalCost(): number {
    return this.medicalTests.reduce((total, item) => total + parseFloat(item.price), 0);
  }

  onSubmit() {  
    const formData = new FormData();
    this.submitted = true;
    if (this.editOrderForm.invalid) {
      return;
    }
    
    const formValues = this.editOrderForm.value;
    formData.append('telephone', formValues.telephone);
    formData.append('note', formValues.note || '');
    formData.append('availability', formValues.availability);
    formData.append('location', formValues.location);
    formData.append('status', formValues.status);
    formData.append('id', String(this.order?.id));
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    let executedAtArray: string[] = this.order?.executed_at ? 
      [...this.order.executed_at] : [formattedDate];
    if (formValues.status !== this.order.status) {
      executedAtArray.push(formattedDate); 
    } else {
      executedAtArray[executedAtArray.length - 1] = formattedDate; 
    }
    formData.append('executedAt[]', executedAtArray.toString());
    formData.append('medicalTestIds[]', this.medicalTests.map(item => item.id).toString());
    this.orderService.updateOrder(this.order.id as number, formData).pipe(
      first()).subscribe( response => {
        Swal.fire({
          title: '',
          text: 'Order Edited',
          icon: 'success',
          confirmButtonColor: '#00ACB1',
        }).then(() =>{
          this.router.navigate(['admin']);
        });
      },
      error => {
        Swal.fire({
          title: 'An error occurred',
          html: 'Please contact us at: <b>info@meshincentre.com</b>',
          icon: 'error',
          confirmButtonColor: '#f37b7b',
        });
      });
  }
  
}
