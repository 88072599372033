import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesRoutingModule } from './pages-routing.module';
import { SharedModule } from '../components/shared.module';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxPaginationModule } from 'ngx-pagination';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxTimelineModule } from '@frxjs/ngx-timeline';
import { capitalisePipe } from '../pipes';

import {
  HomeComponent,
  AboutComponent,
  OurServicesComponent,
  MedicalTestsComponent,
  MedicalTestDetailsComponent,
  FaqComponent,
  AddOrderComponent,
  OrderDetailsComponent,
  OrderTimelineComponent,
  AddMedicalTestComponent,
  ContactComponent,
  CartComponent,
  SettingsComponent,
  DashboardComponent,
  AdminComponent,
  TermsComponent,
  PrivacyComponent,
  EditOrderComponent,
  SingleServiceDetailsComponent,
  PaymentAbortComponent,
  PaymentSuccessComponent,
  PermissionsComponent
} from './index';

@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    OurServicesComponent,
    MedicalTestsComponent,
    MedicalTestDetailsComponent,
    FaqComponent,
    AddOrderComponent,
    OrderDetailsComponent,
    OrderTimelineComponent,
    AddMedicalTestComponent,
    ContactComponent,
    CartComponent,
    SettingsComponent,
    DashboardComponent,
    AdminComponent,
    TermsComponent,
    PrivacyComponent,
    EditOrderComponent,
    PermissionsComponent,
    SingleServiceDetailsComponent,
    PaymentAbortComponent,
    PaymentSuccessComponent,
    capitalisePipe

  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    SharedModule,
    NgxPaginationModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgxIntlTelInputModule,
    SweetAlert2Module,
    NgxTimelineModule,
  ],
})
export class PagesModule { }
