import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moneyFormat'
})
export class MoneyFormatPipe implements PipeTransform {
  transform(value: number): any {
    return Math.abs(Number(value)) >= 1.0e+15
      ? (Math.abs(Number(value)) / 1.0e+15).toFixed(2) + 'Q'
      : Math.abs(Number(value)) >= 1.0e+12
        ? (Math.abs(Number(value)) / 1.0e+12).toFixed(2) + 'T'
        : Math.abs(Number(value)) >= 1.0e+9
          ? (Math.abs(Number(value)) / 1.0e+9).toFixed(2) + 'B'
          : Math.abs(Number(value)) >= 1.0e+6
            ? (Math.abs(Number(value)) / 1.0e+6).toFixed(2) + 'M'
            : Math.abs(Number(value)) >= 1.0e+3
              ? (Math.abs(Number(value)) / 1.0e+3).toFixed(2) + 'K'
              : Math.abs(Number(value));
  }
}
