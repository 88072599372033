import { Component, OnInit } from '@angular/core';
import { CommonData } from '../../models';

@Component({
  selector: 'app-work-process',
  templateUrl: './work-process.component.html',
  styleUrls: ['./work-process.component.scss']
})
export class WorkProcessComponent implements OnInit {
  workprocess: CommonData[] = [];
  constructor() { }

  ngOnInit(): void {
    this.workprocess = [
      {
        icon: "fab fa-searchengin",
        title: "Search For A Test",
        text: "It is a long established fact that a reader will be distracted by the readable."
      },
      {
        icon: "fal fa-prescription-bottle",
        title: "Order For A Test",
        text: "It is a long established fact that a reader will be distracted by the readable."
      },
      {
        icon: "fal fa-check-double",
        title: "Test Executed",
        text: "It is a long established fact that a reader will be distracted by the readable."
      }
    ]
  }

}
