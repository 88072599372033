import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements AfterViewInit {

  ngAfterViewInit() {
    //TODO: Shall check if these are still needed
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }
}
