import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BreadcrumbModule } from 'angular-crumbs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CountUpModule } from 'ngx-countup';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTablesModule } from 'angular-datatables';
import { ImageCropperModule } from 'ngx-image-cropper';

import { PreloaderComponent } from './preloader/preloader.component';
import { HeaderComponent } from './header/header.component';
import { BannerComponent } from './banner/banner.component';
import { CardComponent } from './card/card.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { FooterComponent } from './footer/footer.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { InputTextComponent } from './input-text/input-text.component';
import { InputTextareaComponent } from './input-textarea/input-textarea.component';
import { ServiceSidebarComponent } from './service-sidebar/service-sidebar.component';
import { MedicalTestsComponent } from './medical-tests/medical-tests.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { CounterComponent } from './counter/counter.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { WorkProcessComponent } from './work-process/work-process.component';
import { ClientsComponent } from './clients/clients.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { SelectComponent } from './select/select.component';
import { ServicesComponent } from './services/services.component';
import { ServicesImageComponent } from './services-image/services-image.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TableComponent } from './table/table.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { PasswordStrengthComponent } from'./password-strength/password-strength.component';
import { NavLinksComponent } from './nav-links/nav-links.component'

import { ButtonTextPipe, CarouselConfigPipe } from '../pipes';

@NgModule({
  declarations: [
    PreloaderComponent,
    HeaderComponent,
    HeaderComponent,
    FooterComponent,
    BreadcrumbsComponent,
    CardComponent,
    CheckboxComponent,
    ServiceSidebarComponent,
    MedicalTestsComponent,
    MobileMenuComponent,
    CounterComponent,
    WhyUsComponent,
    InputTextComponent,
    InputTextareaComponent,
    WorkProcessComponent,
    BannerComponent,
    ClientsComponent,
    NewsletterComponent,
    SearchFormComponent,
    SelectComponent,
    ServicesComponent,
    ServicesImageComponent,
    SpinnerComponent,
    TableComponent,
    ImageCropperComponent,
    PasswordStrengthComponent,
    NavLinksComponent,
    ButtonTextPipe,
    CarouselConfigPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    BreadcrumbModule,
    ReactiveFormsModule,
    FormsModule,
    SlickCarouselModule,
    NgxSliderModule,
    CountUpModule,
    NgxPaginationModule,
    DataTablesModule,
    ImageCropperModule
  ],
  exports:[
    PreloaderComponent,
    HeaderComponent,
    HeaderComponent,
    BannerComponent,
    CardComponent,
    CheckboxComponent,
    FooterComponent,
    BreadcrumbsComponent,
    ServiceSidebarComponent,
    MedicalTestsComponent,
    CounterComponent,
    InputTextComponent,
    InputTextareaComponent,
    WhyUsComponent,
    WorkProcessComponent,
    ClientsComponent,
    NewsletterComponent,
    SearchFormComponent,
    SelectComponent,
    ServicesComponent,
    ServicesImageComponent,
    SpinnerComponent,
    TableComponent,
    ImageCropperComponent,
    DataTablesModule,
    ImageCropperModule,
    PasswordStrengthComponent,
    NavLinksComponent,
    ButtonTextPipe,
    SlickCarouselModule
  ]
})
export class SharedModule { }
