<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9 service-details">
  <div class="section sigma_post-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="sigma_post-details-inner mb-md-80">
            <div class="entry-content">
              <div class="row">
              </div>
              <h4 class="entry-title">Terms of Service</h4>
              <div class="pt-2">
                <h5>
                  Thank you for using Go-checks!
                </h5>
                <p>
                  Welcome to Go-checks! These terms and conditions outline the rules and regulations for the use of our
                  platform.
                </p>
                <h5>1. Acceptance of Terms:</h5>
                <p>
                  By accessing or using Go-checks, you agree to abide by these terms and conditions. If you disagree
                  with any part of these terms, you may not use our services.
                </p>
                <h5>2. User Accounts:</h5>
                <p>
                  To access certain features of the platform, you may be required to register for an account. You are
                  responsible for maintaining the confidentiality of your account and password.
                </p>
                <h5>3. Services and Content:</h5>
                <p>
                  Our platform provides access to a range of laboratory tests facilitated by partner laboratories. The
                  results and content provided are for informational purposes only and should not be considered a
                  substitute for professional medical advice.
                </p>

                <h5>4. Privacy Policy:</h5>
                <p>
                  Our Privacy Policy outlines how we collect, use, and protect your personal information. By using our
                  platform, you agree to the terms outlined in the Privacy Policy.
                </p>
                <h5>5. Ordering and Payment:</h5>
                <p>
                  When placing an order for laboratory tests, you agree to provide accurate and complete information.
                  Payment details are processed securely, and you are responsible for any charges incurred.
                </p>
                <h5>6. Sample Collection and Handling:</h5>
                <p>
                  Users are responsible for following sample collection instructions provided. The platform is not liable
                  for any issues arising from improper sample handling.
                </p>
                <h5>7. Test Results:</h5>
                <p>
                  Test results are delivered to users through the platform or via email. Interpretation of results should
                  be done in consultation with a healthcare professional. We are not responsible for any consequences
                  resulting from misinterpretation of results.
                </p>
                <h5>8. Referrals and Discounts:</h5>
                <p>
                  Users may be eligible for referral discounts based on our referral program. The terms and conditions
                  of the referral program are subject to change at our discretion.
                </p>
                <h5>9. Platform Access:</h5>
                <p>
                  We reserve the right to modify, suspend, or discontinue any aspect of the platform at any time
                  without prior notice. We are not liable for any consequences resulting from platform unavailability.
                </p>
                <h5>10. Termination:</h5>
                <p>
                  We reserve the right to terminate or suspend user accounts and access to the platform at our
                  discretion, without prior notice, for any reason.
                </p>
                <h5>11. Governing Law:</h5>
                <p>
                  These terms and conditions are governed by and construed in accordance with the laws of Uganda.
                  Any disputes arising from the use of our platform will be subject to the jurisdiction of court.
                </p>
                <h5>12. Contact Information:</h5>
                <p>
                  For any inquiries or concerns regarding these terms and conditions, please contact us via info@go-checks.com.
                </p>
                <br>
                <br>
                <p>
                  By using Go-checks, you agree to the terms outlined in both our Terms and Conditions and Privacy
                  Policy. If you have any questions or concerns, please contact us via info@go-checks.com.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="login" *ngIf="!(isLoggedIn | async)">
            <div>
              <div class="p-4">
                <p>Get help with your orders, account, and more.</p>
                <a routerLink="/authenticate/login" class="sigma_btn btn-block btn-sm">
                  Login or Sign Up
                </a>
              </div>
            </div>
          </div>
          <div class="login" *ngIf="(isLoggedIn | async)">
            <div>
              <div class="p-4">
                <p>Continue with your orders, account, and more.</p>
                <a routerLink="/home" class="sigma_btn btn-block btn-sm">
                  Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
