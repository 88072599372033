<footer class="sigma_footer style-5 pb-0">
  <div class="container">
    <div class="sigma_info-wrapper style-26 mb-5">
      <div class="sigma_info style-26">
        <div class="sigma_info-title">
          <span class="sigma_info-icon">
            <i class="fal fa-map-marker-alt"></i>
          </span>
        </div>
        <div class="sigma_info-description">
          <p>Our Address</p>
          <p class="secondary-color"><b>Spring Road, Bugolobi</b><br>
            <b>Kisakye complex, Level 2</b>
          </p>
        </div>
      </div>
      <div class="sigma_info style-26">
        <div class="sigma_info-title">
          <span class="sigma_info-icon">
            <i class="fal fa-phone"></i>
          </span>
        </div>
        <div class="sigma_info-description">
          <p>Call Us</p>
          <p class="secondary-color"><b>0780210681</b><br>
            <b>0709734819</b>
          </p>
        </div>
      </div>
      <div class="sigma_info style-26">
        <div class="sigma_info-title">
                    <span class="sigma_info-icon">
                        <i class="fal fa-envelope"></i>
                    </span>
        </div>
        <div class="sigma_info-description">
          <p>Our Mail</p>
          <p class="secondary-color"><b>info@go-checks.com</b>
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="sigma_footer-middle">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="sigma_footer-widget">
            <div class="sigma_footer-logo mb-4">
              <img src="../../../assets/img/go-checks-logo.png" alt="logo">
            </div>
            <div class="row">
              <div class="col-sm-9">
                <p class="mb-0">One test at a time</p>
              </div>
            </div>
            <ul class="sigma_social-icons has-border mt-4 justify-content-start">
              <li>
                <a target="_blank" href="https://wa.me/message/VTM7ZOGDMBPCG1"><i class="fab fa-whatsapp"></i></a>
              </li>
              <li>
                <a target="_blank" href="https://www.facebook.com/profile.php?id=61562506091266"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="https://twitter.com/go_checks" target="_blank"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="https://www.instagram.com/go_checks/" target="_blank"><i class="fab fa-instagram"></i></a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/go-checks" target="_blank"><i class="fab fa-linkedin"></i></a>
              </li>
              <!-- <li>
                <a href="#"><i class="fab fa-google"></i></a>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-sm-3">
          <div class="sigma_footer-widget">
            <!-- TODO: implement the navigation for services in the footer -->
            <h5 class="widget-title">Services</h5>
            <ul class="sigma_footer-links">
              <!-- Data -->
              <li *ngFor="let item of services | slice:0:5">
                <a (click)="goToSingleServiceDetails(item)">{{item.title}}</a>
              </li>
              <!-- Data -->
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-sm-3">
          <div class="sigma_footer-widget">
            <h5 class="widget-title">Useful Links</h5>
            <ul class="sigma_footer-links">
              <li>
                <a routerLink="/">Home</a>
              </li>
              <li>
                <a routerLink="/about">About Us</a>
              </li>
              <!-- <li>
                <a routerLink="/blog">News</a>
              </li> -->
              <li>
                <a routerLink="/faq">FAQ</a>
              </li>
              <li>
                <a routerLink="/contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-sm-6">
          <div class="sigma_footer-widget">
            <h5 class="widget-title">Subscribe</h5>
            <form>
              <input type="email" name="email" placeholder="Email" required>
              <button type="button" class="mt-3 btn-block">Subscribe</button>
              <p class="mb-0 mt-3">Get The Latest Updates via email. Any time you may unsubscribe</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="sigma_footer-bottom d-block d-sm-flex align-items-center justify-content-between">
      <div class="sigma_footer-copyright mt-0 mb-3 mb-sm-0">
        <p class="mb-0">© Go-checks
          <a href="#">{{ currentYear }}</a>
          | All Rights Reserved
        </p>
      </div>
      <ul class="sigma_footer-links">
        <li>
          <a routerLink="/privacy">Privacy</a>
        </li>
        <li>
          <a routerLink="/terms">Terms</a>
        </li>
        <li>
          <a routerLink="/contact">Help</a>
        </li>
      </ul>
      <small font-size>Designed and programmed by <a href="https://meshincentre.com" target="_blank">Mesh Innovation Centre</a></small>
    </div>
  </div>
</footer>
