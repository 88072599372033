import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  DataService,
  MedicalTestService,
} from '../../services';
import { MedicalTest } from 'src/app/models';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  cartItems: MedicalTest[] = [];
  medicalTests: MedicalTest[] = [];

  constructor (
    private router: Router,
    private dataService: DataService,
    private medicalTestService: MedicalTestService,
  ) { }

  ngOnInit(): void {
    this.medicalTestService.getMedicalTests().subscribe(
      medicalTests => {
        this.medicalTests = medicalTests as MedicalTest[];
        this.dataService.saveAllMedicalTests(this.medicalTests);
    });
  }

  onSubmit() {
    this.router.navigateByUrl('/medical-tests');
  }

}
