import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  headers: HttpHeaders = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
  constructor(private http: HttpClient) { }

  /**
  * Gets All Users
  */
  getUsers() {
    return this.http.get(
      'users',
      { headers: this.headers })
      .pipe(map(response => response));
  }

  /**
  * Gets Total number of Users
  */
   getTotalUsers() {
    return this.http.get(
      'total-users',
      { headers: this.headers })
      .pipe(map(response => response));
  }

   /**
   * Gets One User
   */
   getSingleUser(id: number) {
    return this.http.get(
      `user/${id}`,
      { headers: this.headers })
      .pipe(map(response => response as User));
  }

  /**
   * Updates the user in the db
   *
   * @param user to edit
   * @param id to user's id
   * @returns Observable<any>
   */
  editUser(formData: any, id: number) {
    return this.http.put(`user/${id}`, formData);
  }

  /**
   * Edits the user in the db
   *
   * @param data for the password to change
   * @returns Observable<any>
   */
  changePassword(data: any): Observable<any> {
    return this.http.put('change-password', data);
  }

  /**
   * Sends email to server to check
   *
   * @param emailData for the email
   * @returns Observable<any>
   */
  forgotPassword(emailData: any): Observable<any> {
    return this.http.post('password/email', emailData);
  }

  /**
   * Resets the password
   *
   * @param data for the email
   * @returns Observable<any>
   */
  resetPassword(data: any): Observable<any> {
    return this.http.post('password/reset', data);
  }

  /**
   * Deletes the user from the db
   *
   * @param id to delete
   * @returns Observable<any>
   */
  deleteUser(id: number) {
    return this.http.delete(
      `user/${id}`,
      { headers: this.headers });
  }
}
