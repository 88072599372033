<app-banner></app-banner>
<app-search-form (click)="onSubmit()"></app-search-form>
<app-services></app-services>
<div class="section bg-secondary-1">
  <div class="container">
    <app-why-us></app-why-us>
    <app-counter></app-counter>
  </div>
</div>
<div class="section pb-0 bg-gray"></div>
<div class="section section-padding bg-gray">
  <div class="container">
    <div class="section-title centered">
      <span class="subtitle">What We do</span>
      <h3 class="title">Our Services</h3>
    </div>
    <app-services-image></app-services-image>
    <div class="margin-negative">
      <div class="row">
        <div class="col-lg-6">
          <app-newsletter></app-newsletter>
        </div>
        <div class="col-lg-6">
          <app-clients></app-clients>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="section section-padding"></div>
