<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9 service-details">
  <div class="section sigma_post-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="sigma_post-details-inner mb-md-80">
            <div class="entry-content">
              <div class="row">
              </div>
              <h4 class="entry-title">Your privacy choices</h4>
              <div class="pt-2">
                <h5>
                  Thank you for using Go-checks!
                </h5>
                <p>
                  At Go-checks, we are committed to protecting your privacy. This Privacy Policy outlines how we collect,
                  use, disclose, and safeguard your personal information.
                </p>
                <h5>1. Information Collected:</h5>
                <p>
                  We collect personal information, including but not limited to, name, contact details, and health-
                  related data, to provide our services.
                </p>
                <h5>2. Use of Information:</h5>
                <p>
                  Personal information is used to process orders, provide test results, and improve our services. We do
                  not sell or share your information with third parties for marketing purposes.
                </p>
                <h5>3. Data Security:</h5>
                <p>
                  We employ industry-standard security measures to protect your personal information. However, no
                  method of transmission over the internet or electronic storage is 100% secure.
                </p>

                <h5>4. Cookies:</h5>
                <p>
                  We may use cookies to enhance user experience. Users may choose to disable cookies, but this may
                  affect the functionality of certain parts of our platform.
                </p>
                <h5>5. Third-Party Links:</h5>
                <p>
                  Our platform may contain links to third-party websites. We are not responsible for the privacy
                  practices or content of these sites.
                </p>
                <h5>6. Data Retention:</h5>
                <p>
                  We retain user data for as long as necessary to provide services and as required by applicable laws.
                  Laboratory tests results are deleted from the platform after 30 days from the time they become available.
                </p>
                <h5>7. Updates to Privacy Policy:</h5>
                <p>
                  This Privacy Policy may be updated periodically. Users are encouraged to review the policy for any changes.
                </p>
                <br>
                <br>
                <p>
                  By using Go-checks, you agree to the terms outlined in both our Terms and Conditions and Privacy
                  Policy. If you have any questions or concerns, please contact us via info@go-checks.com.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="login" *ngIf="!(isLoggedIn | async)">
            <div>
              <div class="p-4">
                <p>Get help with your orders, account, and more.</p>
                <a routerLink="/authenticate/login" class="sigma_btn btn-block btn-sm">
                  Login or Sign Up
                </a>
              </div>
            </div>
          </div>
          <div class="login" *ngIf="(isLoggedIn | async)">
            <div>
              <div class="p-4">
                <p>Continue with your orders, account, and more.</p>
                <a routerLink="/home" class="sigma_btn btn-block btn-sm">
                  Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
