import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OtherServices } from '../../models';
import { DataService, DocumentService } from '../../services';

@Component({
  selector: 'app-services-image',
  templateUrl: './services-image.component.html',
  styleUrls: ['./services-image.component.scss']
})

export class ServicesImageComponent implements OnInit {
  services: OtherServices[];
  slickCarouselConfig: any;

  constructor(
    private dataService: DataService,
    private documentService: DocumentService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.slickCarouselConfig = {...this.documentService.getslickCarouselConfig(),
      infinite: true, 
      slidesToShow: 3, 
      slidesToScroll: 3 
    };
    this.services = this.dataService.getOtherServices();
  }

  goToSingleServiceDetails(service: OtherServices) {
    this.dataService.saveDataToView(service);
    this.router.navigate(['single-service-details']);
  }
}
