import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormArray
} from '@angular/forms';
import { Router } from '@angular/router';
import { Cart, MedicalTest, Order, User } from '../../models';
import { 
  AuthenticationService, 
  CartService, 
  DataService,
  OrderService,
  UtilsService 
} from '../../services';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-add-order',
  templateUrl: './add-order.component.html',
  styleUrls: ['./add-order.component.scss']
})
export class AddOrderComponent implements OnInit {
  addOrderForm: FormGroup;
  paymentForm: FormGroup;
  submitted = false;
  user: User;
  cartItems: MedicalTest[] = [];
  separateDialCode = false;
  userCart: Cart;
  locations: string[];
  disableDate: string;
  orderToSave: [];

  constructor (
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private dataService: DataService,
    private cartService: CartService,
    private orderService: OrderService,
    private router: Router,
    private utilsService: UtilsService
    ) { }

  ngOnInit(): void {
    this.user = this.authenticationService.currentUser()['user'];
    this.locations = this.dataService.getLocationLabels();
    this.cartService.getUserCart(this.user.id).subscribe((cartTests: Cart) => {
      this.userCart = cartTests;
      if (cartTests.medical_tests && cartTests.medical_tests.length > 0) {
        this.cartItems = cartTests.medical_tests;
      }
    });
    this.addOrderForm = this.formBuilder.group({
      clientName: [this.user?.firstname + ' ' + this.user?.lastname],
      email: [this.user?.email],
      telephone: [this.user?.telephone, Validators.required],
      birthDate: [this.user?.birth_date],
      availability: ['', Validators.required],
      district: ['', Validators.required],
      location: ['', Validators.required],
      note: ['']
    });
    this.disableDate = this.utilsService.getMinDate();
    this.addOrderForm.statusChanges.subscribe(
      status => {
        if (status === 'INVALID') {
          this.saveOrderInformation();
        }
      })
  }

  get f() {
    return this.addOrderForm.controls;
  }

  getTotalCost(): number {
    return this.cartItems.reduce((total, item) => total + parseFloat(item.price), 0);
  }

  getNarrative(): string {
    return this.cartItems.map(item => item.name).join(',');
  }

  saveOrderInformation() {
    const plainFormData = {
      ...this.addOrderForm.value,
      userId: this.user?.id,
    };
    if (this.f.district?.value && this.f.location?.value) {
      plainFormData.location = `${this.f.district.value} ${this.f.location.value}`;
    }
    this.dataService.saveToLocalStorage('orderData', plainFormData);
    this.dataService.saveToLocalStorage('cartItems', this.cartItems);
  }

}
