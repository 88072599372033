import { Pipe } from '@angular/core';
import { MedicalTest } from '../models/medical-test.model';

@Pipe({
  name: 'buttonText'
})
export class ButtonTextPipe {
  transform(cartItems: MedicalTest[], medicalTest: MedicalTest) {
    return cartItems.some(item => item.id === medicalTest.id) ? 'Remove' : 'Add to cart';
  }
}
