<div class="sigma_cta style-14">
  <div class="sigma_cta-content d-block d-sm-flex align-items-center">
    <i class="flaticon-message"></i>
    <h4 class="mt-3 mt-sm-0">Subscribe Our Email For Newsletter</h4>
  </div>
  <p class="text-white">It is a long established fact that a reader will be distracted by the readable content of a page
    when looking at its layout.</p>
  <form>
    <div class="input-group has-border">
      <input type="email" name="email" placeholder="Email Address" required>
      <div class="input-group-append">
        <button type="button" class="light">
          <i class="fal fa-envelope mr-2"></i>
          Subscribe
        </button>
      </div>
    </div>
  </form>
</div>
