import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';
import { RoleName, PermissionId } from '../models';
import {
  HomeComponent,
  AboutComponent,
  OurServicesComponent,
  MedicalTestsComponent,
  MedicalTestDetailsComponent,
  AddOrderComponent,
  FaqComponent,
  OrderDetailsComponent,
  OrderTimelineComponent,
  AddMedicalTestComponent,
  ContactComponent,
  CartComponent,
  SettingsComponent,
  DashboardComponent,
  AdminComponent,
  TermsComponent,
  PrivacyComponent,
  EditOrderComponent,
  SingleServiceDetailsComponent,
  PaymentAbortComponent,
  PaymentSuccessComponent,
  PermissionsComponent
} from './index';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent, data: { breadcrumb: 'About Us' } },
  { path: 'our-services', component: OurServicesComponent, data: { breadcrumb: 'What we do' } },
  { path: 'medical-tests', component: MedicalTestsComponent, data: { breadcrumb: 'Medical Tests' }},
  { path: 'faq', component: FaqComponent, data: { breadcrumb: 'FAQ' }},
  { path: 'medical-test-details', component: MedicalTestDetailsComponent, data: { breadcrumb: 'Test Details' }},
  { path: 'add-order', component: AddOrderComponent, data: { breadcrumb: 'Order Test' }},
  { path: 'order-details', component: OrderDetailsComponent, data: { breadcrumb: 'Order Details' }},
  { path: 'order-timeline', component: OrderTimelineComponent, data: { breadcrumb: 'Track Orders' }},
  {
    path: 'add-medical-test',
    component: AddMedicalTestComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Add Test',
      roles: [
        RoleName.ADMIN_ONE,
        RoleName.ADMIN_TWO,
        RoleName.ADMIN_THREE,
        RoleName.ADMIN_FOUR
      ],
      permissions: [PermissionId.ADD_MEDICAL_TEST]
    }
  },
  {
    path: 'permissions',
    component: PermissionsComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Permissions',
      roles: [RoleName.ADMIN_ONE]
    }
  },
  { path: 'contact', component: ContactComponent, data: { breadcrumb: 'Contact Us' }},
  { path: 'cart', component: CartComponent, data: { breadcrumb: 'Cart' }},
  { path: 'settings', component: SettingsComponent, data: { breadcrumb: 'Settings' }},
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Dashboard',
    }
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Admin',
      roles: [
        RoleName.ADMIN_ONE,
        RoleName.ADMIN_TWO,
        RoleName.ADMIN_THREE,
        RoleName.ADMIN_FOUR
      ]
    }
  },
  { path: 'terms', component: TermsComponent, data: { breadcrumb: 'terms' }},
  { path: 'privacy', component: PrivacyComponent, data: { breadcrumb: 'privacy' }},
  {
    path: 'edit-order',
    component: EditOrderComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Edit Order',
      roles: [
        RoleName.ADMIN_ONE,
        RoleName.ADMIN_TWO,
        RoleName.ADMIN_THREE,
        RoleName.ADMIN_FOUR
      ],
      permissions: [PermissionId.EDIT_ORDER]
    }
  },
  { path: 'single-service-details', component: SingleServiceDetailsComponent, data: { breadcrumb: 'Service' }},
  {
    path: 'payment-abort',
    component: PaymentAbortComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Payment Aborted',
    }
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Payment Successful',
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
