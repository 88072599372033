<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9 service-details">
  <div class="section sigma_post-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="sigma_post-details-inner mb-md-80">
            <div class="entry-content">
              <a class="btn-link primary-color" href="javascript:void(0);" onclick="history.back();"> 
                <i class="fal fa-hand-point-left"></i> &nbsp; &nbsp;
                  Continue Shopping 
              </a>
              <br>
              <ng-container *ngIf="medicalTest && medicalTest.images && medicalTest.images.length > 0">
                <img 
                  [src]="selectedImageIndex === 0 ? medicalTest.images[0] : selectedImage" 
                  [alt]="medicalTest.name" 
                  class="medical-test-image w-100">
              </ng-container>       
              <div class="row">
                <div class="col-md-12 image-carousel" *ngIf="medicalTest && medicalTest.images && medicalTest.images.length > 1">
                  <div *ngFor="let image of medicalTest.images; let i = index;">
                    <img [src]="image" [alt]="medicalTest.name" (click)="selectImage(i)">
                  </div>
                </div>
              </div>                          
              <h4 class="entry-title">{{medicalTest?.name}}</h4>
              <div class="test-extra-details sigma_info-wrapper style-26">
                <div class="sigma_info style-26 test-extra-details-inner">
                  <div class="sigma_info-title">
                    <span class="sigma_info-icon">
                      <i class="fal fa-money-check"></i>
                    </span>
                  </div>
                  <div class="sigma_info-description">
                    <p>{{medicalTest?.price + ' UGX'}}</p>
                  </div>
                </div>
                <div class="sigma_info style-26 test-extra-details-inner">
                  <div class="sigma_info-title">
                    <span class="sigma_info-icon">
                      <i class="far fa-clock"></i>
                    </span>
                  </div>
                  <div class="sigma_info-description">
                    <p>{{medicalTest?.duration}}</p>
                  </div>
                </div>
              </div>
              <div class="pt-5" [innerHtml]="medicalTest?.description"></div>
            </div>
            <!-- Post Meta Start -->
            <!-- TODO: decide if needed-->
            <!-- <div class="sigma_post-details-meta">
              <div class="sigma_post-details-meta-item sigma_post-share">
                <h5>Share</h5>
                <ul class="sigma_sm">
                  <li *ngFor="let social of socialShare(item.title)">
                    <a href="javascript:void(0)" (click)="openSocialPopup(social)">
                      <i [class]="social.iconClass"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div> -->
            <!-- Post Meta End -->
          </div>
        </div>
        <!-- Sidebar Start -->
        <div class="col-lg-4">
          <app-service-sidebar 
            [medicalTest]="medicalTest"
            [cartItems]="cartItems"
            [isShowAddCart]="isShowAddCart"
            >
          </app-service-sidebar>
        </div>
        <!-- Sidebar End -->
      </div>
    </div>
  </div>
</div>
