<div class="sidebar">
  <div class="order-test" *ngIf="isShowAddCart">
    <div>
      <h4 class="order-test-title">Order Test</h4>
      <div class="p-2">
        <p>Vivamus suscipit tortor eget felis porttitor volutpat.</p>
        <button class="sigma_btn btn-sm mb-3" (click)="addToCart(medicalTest)">
          {{ cartItems | buttonText: medicalTest }}
        </button>
      </div>
    </div>
  </div>
  <!-- Follow Widget -->
  <!-- TODO: decide if still needed -->
  <!-- <div class="widget widget-follow">
    <h5 class="widget-title">Follow Us</h5>
    <div class="social-buttons">
      <a href="#" class="btn-block sigma_btn">
        <div class="follow-icon d-flex align-items-center">
          <i class="fab fa-instagram"></i>
          <p class="mb-0">
            Instagram
            <span>225.5k Followers</span>
          </p>
        </div>
        <i class="fal fa-chevron-right"></i>
      </a>
      <a href="#" class="btn-block sigma_btn">
        <div class="follow-icon d-flex align-items-center">
          <i class="fab fa-twitter"></i>
          <p class="mb-0">
            Twitter
            <span>225.5k Followers</span>
          </p>
        </div>
        <i class="fal fa-chevron-right"></i>
      </a>
      <a href="#" class="btn-block sigma_btn">
        <div class="follow-icon d-flex align-items-center">
          <i class="fab fa-facebook-f"></i>
          <p class="mb-0">
            Facebook
            <span>225.5k Followers</span>
          </p>
        </div>
        <i class="fal fa-chevron-right"></i>
      </a>
      <a href="#" class="btn-block sigma_btn">
        <div class="follow-icon d-flex align-items-center">
          <i class="fab fa-youtube"></i>
          <p class="mb-0">
            Youtube
            <span>225.5k Followers</span>
          </p>
        </div>
        <i class="fal fa-chevron-right"></i>
      </a>
    </div>
  </div> -->
  <div class="widget widget-blockquote p-0 border-0">
    <blockquote class="blockquote">
      <cite>Request a Quote</cite>
      <p>Cras ultricies ligula sed magna dictum porta. Vivamus suscipit tortor eget felis porttitor volutpat.</p>
      <a 
        routerLink="/contact" 
        [state]="{ data: medicalTest ? medicalTest.name : otherService.title }" 
        class="sigma_btn btn-sm">
        Request Quote
      </a>
    </blockquote>
  </div>
  <!-- Category Widget -->
  <div class="widget widget-categories">
    <h5 class="widget-title">Categories</h5>
    <ul class="categories-list">
      <li *ngFor="let item of categories | slice:0:4; let i = index;" class="category-item">
        <a (click)="filterMedicalTests({ category: item.title })" class="category-link">
          {{ item.title }}
        </a><span>{{ categoryCounts[item.title] || 0 }}</span>
      </li>
    </ul>    
  </div>
  <!-- Subscribe Widget -->
  <!-- TODO: find out if newsletters will be used -->
  <!-- <div class="widget widget-newsletter">
    <h5 class="widget-title">Join Newsletter</h5>
    <form>
      <input type="email" name="email" placeholder="Enter your email" required>
      <button type="button" class="btn-block mt-4">Subscribe</button>
    </form>
  </div> -->
</div>
