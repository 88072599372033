<div class="sigma_banner-info style-2">
  <div class="container">
    <div class="sigma_cta style-13">
      <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <label>Search for a Test</label>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <div class="input-group">
                <i 
                  class="d-none d-sm-block"
                  [ngClass]="searchForm.controls['searchInput'].value ? 'fal fa-times' : 'fal fa-search'"
                ></i>
                <i 
                  class="d-none d-sm-block"
                  [ngClass]="searchForm.controls['searchInput'].value ? 'fal fa-times' : 'fal fa-search'"
                  (click)="onCleared('fa-times', 'searchInput')"
                ></i>
                <input type="text" class="topic-field" placeholder="Find a Test ..." formControlName="searchInput">
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <div class="input-group">
                <i 
                  class="d-none d-sm-block"
                  [ngClass]="searchForm.controls['duration'].value ? 'fal fa-times' : 'fal fa-map-marker-alt'"
                  (click)="onCleared('fa-times', 'duration')"
                  ></i>
                <input type="text" class="location-field" placeholder="Duration" formControlName="duration">
                <div class="input-group-append">
                  <button type="submit"><i class="fal fa-search mr-1"></i> Find Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </form>

    </div>
  </div>
</div>