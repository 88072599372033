<div class="row">
  <!-- Data -->
  <div class="container services-section">
    <div class="profile-item">
      <div class="row ngx-slick-carousel-container">
        <ngx-slick-carousel class="profile-slider" [config]="slickCarouselConfig ">
          <div ngxSlickItem class="col-lg-4 col-md-6 single-service" *ngFor="let item of services">
            <div (click)="goToSingleServiceDetails(item)" class="sigma_service style-17">
              <div class="sigma_service-thumb">
                <img [src]="item?.images?.[0]" [alt]="item.title">
              </div>
              <div class="sigma_service-body">
                <h5>
                  {{item.title}}
                </h5>
                <p>{{item.introduction | slice:0:95}}</p>
                <a class="btn-link primary-color">
                  Read More
                  <i class="fal fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
  <!-- Data -->
</div>