import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services';
import { Order, OrderStatus } from '../../models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-timeline',
  templateUrl: './order-timeline.component.html',
  styleUrls: ['./order-timeline.component.scss']
})

export class OrderTimelineComponent implements OnInit {
  order: Order;
  orderStatus: OrderStatus[];
  completedStatuses: boolean[] = [];

  constructor (
    private dataService: DataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.dataService.getDataToView().subscribe(order => {
      if (order) {
        this.order = order as Order;
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
    this.orderStatus = this.dataService.getOrderStatus();
    this.calculateCompletedStatuses();
  }

  calculateCompletedStatuses(): void {
    const statusIndex = this.orderStatus.findIndex(status => status.status === this.order.status);
    if (!this.order?.status || !this.orderStatus || statusIndex === -1) {
      return;
    }
    this.completedStatuses = this.orderStatus.map((_, index) => index <= statusIndex);
  }
}
