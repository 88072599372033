<div class="account-pages section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10">
        <app-card [bodyClass]="'p-0'">
          <div body>
            <div class="row">
              <div class="col-6 gochecks-form">
                <div class="mx-auto mb-5">
                  <a routerLink="/">
                    <img src="assets/img/go-checks-logo.png" alt="" height="42"/>
                  </a>
                </div>
                <h6 class="h6 mb-0 mt-4">Welcome back!</h6>
                <p class="text-muted mt-1 mb-4">Enter your credentials to access your <b>Go-Check's</b> account.</p>
                <form
                  class="authentication-form"
                  name="loginForm"
                  [formGroup]="loginForm"
                  (ngSubmit)="onSubmit()"
                  novalidate
                >
                  <div class="row">
                    <div class="col-md-12">
                      <app-input-text
                        [textLabel]="'Email Address: *'"
                        [isIconDisplayed]="true"
                        [inputIcon]="'fa fa-envelope'"
                        [type]="'email'"
                        [invalidInput]="submitted && f.email.invalid"
                        formControlName="email"
                        [formGroup]="loginForm"
                      >
                      </app-input-text>
                      <div *ngIf="submitted && f.email.errors" class="is-invalid">
                        <div *ngIf="f.email.errors.required">Email required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <app-input-text
                        [textLabel]="'Password: *'"
                        [type]="isPasswordHidden ? 'password' : 'text'"
                        [isIconDisplayed]="true"
                        [inputIcon]="'fa fa-lock'"
                        [type]="'password'"
                        [invalidInput]="submitted && f.password.invalid"
                        formControlName="password"
                        [formGroup]="loginForm"
                      ></app-input-text>
                      <i
                        *ngIf="f.password.value"
                        [ngClass]="isPasswordHidden ? 'fal fa-eye' : 'fal fa-eye-slash'"
                        (click)="hidePassword()">
                      </i>
                      <div *ngIf="submitted && f.password.errors" class="is-invalid">
                        <div *ngIf="f.password.errors.required">Password required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <a routerLink="/authenticate/forgot-password"
                       class="float-right text-muted text-unline-dashed ml-1 mb-2">Forgot your
                      password?</a>
                  </div>
                  <div class="form-group mb-0 text-center">
                    <button class="btn btn-primary btn-block authentication-btn" type="submit"> Log In
                    </button>
                  </div>
                </form>
              </div>
              <div class="col-6 authentication-sideimage">
                <div class="auth-page-sidebar">
                  <div class="overlay"></div>
                  <div class="auth-user-testimonial">
                    <p class="text-one font-weight-bold text-white mb-1">Order For A Test Online!</p>
                    <p class="text-two">"It's quick and convenient"</p>
                    <p>- Go-Checks</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">
              Don't have an account?
              <a
                routerLink="/authenticate/create-account"
                class="secondary-color font-weight-bold ml-1"
              >
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
