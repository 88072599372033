import { Role } from './role.model';

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  birth_date?: string;
  address: string;
  password: string;
  gender: string;
  telephone: string;
  role_id: number; 
  role: Role; 
  created_at?: string;
  email_verified_at?: string;
  access_token?: string;
}
