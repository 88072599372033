<app-breadcrumbs></app-breadcrumbs>
<div class="section sigma_post-details">
  <div class="container">
    <div class="row">
      <div class="col-lg-10">
        <div class="sigma_post-details-inner">
          <div class="entry-content">
            <h3> Order Details</h3>
            <div class="sigma_team style-17 mb-0">
              <div class="row no-gutters">
                <div class="col-md-4">
                  <div class="sigma_team-thumb">
                    <img [src]="orderDetails[0].images" >
                  </div>
                </div>
                <div class="ml-4">
                  <div class="sigma_team-body">
                    <h5>
                      {{order.order_number}}
                    </h5>
                    <div class="sigma_team-info mt-4">
                      <span><i class="fal fa-calendar-day"></i>Placed On: {{ order.created_at | date }}</span>
                      <span><i class="fal fa-wallet"></i> Status: {{ order.status }}</span>
                      <span><i class="fal fa-wallet"></i>Total Cost: {{ getTotalPrice(orderDetails) + ' UGX'}}</span>
                      <span><i class="fal fa-building"></i>Location: {{ order.location }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="detail-menu-list">
              <div class="row no-gutters">
                <div style="font-weight: bold; color: red;">
                  TODO: Implement the remarks and status of the order (timeline preferable) here. If clicked the content should replace the overview content</div>
                <div class="col-md-4">
                  <div class="menu nav-item">
                    <a pageScroll href="#overview" class="nav-link active p-0">Overview</a>
                  </div>
                </div>
              </div>
            </div> -->
            <div id="overview" class="p-4">
              <h4>Overview</h4>
              <div class="single-order" *ngFor="let medicalTest of orderDetails; let i = index">
                <ul class="list-group">
                  <li class="list-group-item">
                    <div class="row single-order-item">
                      <div class="col-lg-4 single-order-image">
                        <img [src]="medicalTest?.images?.[0]" >
                      </div>
                      <div class="col-lg-3">
                        <div>{{ medicalTest.name }} </div>
                      </div>
                      <div class="col-lg-3">
                        <div>{{ medicalTest.price + ' UGX'}}</div>
                      </div>
                      <div class="col-lg-2">
                        <button class="sigma_btn btn-block btn-sm mb-3" (click)="addToCart(medicalTest)">
                          {{ cartItems | buttonText: medicalTest }}
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="spacer">
            </div>
            <!-- TODO: implement when google maps is active -->
            <!-- <div id="contact">
              <h4>Location </h4>
              <div class="sigma_contact-wrapper">
                <div class="sigma_contact-map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.9914406081493!2d2.292292615201654!3d48.85837360866272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e2964e34e2d%3A0x8ddca9ee380ef7e0!2sEiffel%20Tower!5e0!3m2!1sen!2sin!4v1571115084828!5m2!1sen!2sin"
                    height="600" allowfullscreen="">
                  </iframe>
                </div>
              </div>
            </div> -->
            <div class="spacer">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
