import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MedicalTest, Order } from '../../models';
import {CartService, DataService } from '../../services';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})

export class OrderDetailsComponent implements OnInit {
  orderDetails: MedicalTest[];
  order: Order;
  totalPrice: number;
  cartItems: MedicalTest[] = [];

  constructor(
    private router: Router,
    private cartService: CartService,
    private dataService: DataService,
  ) {
  }

  ngOnInit(): void {
    this.dataService.getDataToView().subscribe(dataToView => {
      if (dataToView !== null) {
        this.order = dataToView as Order;
        this.orderDetails = this.order.medical_tests as MedicalTest[];
        this.totalPrice = this.getTotalPrice(this.orderDetails);
      } else {
        this.router.navigate(['/dashboard']);
      }
    });
  }

  getTotalPrice(orderDetails: MedicalTest[]): number {
    return orderDetails.reduce((total, medicalTest) => total + parseFloat(medicalTest.price), 0) ;
  }

  addToCart(medicalTest: MedicalTest) {
    this.cartService.getCartMedicalTests().subscribe(cartItems => this.cartItems = cartItems);
    this.dataService.addToCart(medicalTest, this.cartItems);
  }
}
