export enum RoleName {
  ADMIN_ONE = 'Admin', 
  ADMIN_TWO = 'Admin1.1', 
  ADMIN_THREE = 'Admin1.2',
  ADMIN_FOUR = 'Admin1.3',
}

import { Permission } from './permission.model';
export interface Role {
  id: number;
  name: string;
  permissions: Permission[];
}

