<app-breadcrumbs></app-breadcrumbs>
<div class="section pt-5">
  <div class="container">
    <!-- TODO: Shall decide if still needed -->
    <!--div class="section-title centered">
      <span class="subtitle">Call to Action</span>
      <h3 class="title">Make a Request</h3>
    </div-->
    <div class="sigma_form style-2">
      <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" >
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <input
                type="text"
                placeholder="Enter Full Name"
                name="name"
                formControlName="fullname"
              >
              <div *ngIf="submitted && f.fullname.errors" class="is-invalid">
                <div *ngIf="f.fullname.errors.required">Fullname required</div>
                <div
                  *ngIf="f.fullname.errors.minlength"
                >
                  Must be at least 2 characters
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input
                type="email"
                placeholder="Email Address"
                name="email"
                formControlName="email"
              >
              <div *ngIf="submitted && f.email.errors" class="is-invalid">
                <div *ngIf="f.email.errors.required">Email required</div>
                <div *ngIf="f.email.errors.email ||
                         f.email.errors.pattern"
                >Email must be a valid email address</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input
                type="text"
                placeholder="Phone number"
                name="phone"
                formControlName="telephone"
                >
              <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
                <div *ngIf="f.telephone.errors.required">Telephone required</div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <input
                type="text"
                placeholder="Subject"
                name="subject"
                formControlName="subject"
              >
              <div *ngIf="submitted && f.subject.errors" class="is-invalid">
                <div *ngIf="f.subject.errors.required">Subject required</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <textarea
                rows="10"
                placeholder="Enter Message"
                name="message"
                formControlName="message"
              >
              </textarea>
              <div *ngIf="submitted && f.message.errors" class="is-invalid">
                <div *ngIf="f.message.errors.required">Message required</div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            <button type="submit" >Submit Request</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- TODO: Add locations for Go-checks offices-->
<div class="sigma_contact-map">
  <iframe
    width="600"
    height="450"
    style="border:0"
    loading="lazy"
    allowfullscreen
    referrerpolicy="no-referrer-when-downgrade"
    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1994.9782243147148!2d32.621802213120695!3d0.3192607157477119!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1620214152684!5m2!1sen!2s">
  </iframe>
</div>
