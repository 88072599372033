import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OtherServices } from '../../models';
import { DataService, DocumentService } from '../../services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

// TODO: Remove the extended classes and instead get data from the backend
export class FooterComponent implements OnInit  {
  services: OtherServices[];
  currentYear: number;

  constructor(
    private dataService: DataService,
    private documentService: DocumentService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.services = this.dataService.getOtherServices();
  }

  goToSingleServiceDetails(service: OtherServices) {
    this.dataService.saveDataToView(service);
    this.router.navigate(['single-service-details']).then(() => {
      window.scrollTo(0, 0);
    });
  }  
}
