<div class="sigma_logo-wrapper">
  <a routerLink="/" class="sigma_logo">
    <img src="../../../assets/img/go-checks-logo.png" alt="logo">
  </a>
</div>
<ul class="navbar-nav">
  <li class="menu-item" *ngFor="let item of navigation">
    <a
      routerLink="{{ item.link }}"
      routerLinkActive="is-active"
      [routerLinkActiveOptions]="{ exact: true }"
      (click)="closeMobileMenu()"
      *ngIf="(isLoggedIn | async) ? 
        ((isAdmin | async) ? item.link !== '/dashboard' : item.link !== '/admin') : 
        item.link !== '/admin' && item.link !== '/dashboard'"
      >
        {{ item.linkText }}
    </a>
  </li>
</ul>
<app-nav-links 
  [showSpan]="false"
  [showCart]="false" 
  (navToggle)="closeMobileMenu()">
</app-nav-links>
