<div class="section section-padding sigma_service-sec style-16 service">
  <div class="container">
    <div class="sigma_service-sec">
      <div class="container medical-tests-section">
        <!-- TODO: this implementation will be revisted -->
        <!-- <ng-container *ngIf="groupedMedicalTests && groupedMedicalTests.length > 0">
          <div *ngFor="let item of groupedMedicalTests | slice:0:4">
            <div class="profile-item">
              <h4 class="title">{{ item.category }}</h4>
              <div class="row ngx-slick-carousel-container">
                <ngx-slick-carousel
                  class="profile-slider"
                  [config]="slickCarouselConfig | carouselConfig: item"
                >
                  <div ngxSlickItem *ngFor="let medicalTest of item.groupItems">
                    <img [src]="medicalTest?.images?.[0]" alt="{{ medicalTest.name }}">
                    <div (click)="goToMedicalTestDetails(medicalTest)" class="profile-inner">
                      <div class="duration-price-container">
                        <span>Duration: <b>{{ medicalTest.duration }}</b></span>
                        <span>{{ medicalTest.price }} UGX</span>
                      </div>
                      <a class="primary-color">
                        <h5 class="test-title">{{ medicalTest.name }}</h5>
                      </a>
                      <p class="pb-0">{{ medicalTest.description }}</p>

                      <a class="btn-link primary-color">View<i class="icofont-swoosh-right"></i></a>
                    </div>
                  </div>
                </ngx-slick-carousel>
              </div>
            </div>
          </div>
        </ng-container> -->
        <!-- TODO: create variables for each category(4) -->
        <!-- TODO: create 4 carousels for each category -->
        <!-- TODO: this is not the ideal implementation for now and shall be solved later -->
          <div *ngIf="category1.length > 0" class="profile-item">
            <!-- TODO: replace this with the title of the category -->
            <h4 class="title">Basic Health Checkups</h4>
            <div class="row ngx-slick-carousel-container">
              <ngx-slick-carousel
                class="profile-slider"
                [config]="slickCarouselConfig"
              >
                <div ngxSlickItem *ngFor="let medicalTest of category1">
                  <img [src]="medicalTest?.images?.[0]" alt="{{ medicalTest.name }}">
                  <div (click)="goToMedicalTestDetails(medicalTest)" class="profile-inner">
                    <div class="duration-price-container">
                      <span>Duration: <b>{{ medicalTest.duration }}</b></span>
                      <span>{{ medicalTest.price }} UGX</span>
                    </div>
                    <a class="primary-color">
                      <h5 class="test-title">{{ medicalTest.name }}</h5>
                    </a>
                    <p class="pb-0">{{ medicalTest.description }}</p>
                    <a class="btn-link primary-color">View<i class="icofont-swoosh-right"></i></a>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
          <div *ngIf="category2.length > 0" class="profile-item">
            <h4 class="title">Routine Blood Tests</h4>
            <div class="row ngx-slick-carousel-container">
              <ngx-slick-carousel
                class="profile-slider"
                [config]="slickCarouselConfig"
              >
                <div ngxSlickItem *ngFor="let medicalTest of category2">
                  <img [src]="medicalTest?.images?.[0]" alt="{{ medicalTest.name }}">
                  <div (click)="goToMedicalTestDetails(medicalTest)" class="profile-inner">
                    <div class="duration-price-container">
                      <span>Duration: <b>{{ medicalTest.duration }}</b></span>
                      <span>{{ medicalTest.price }} UGX</span>
                    </div>
                    <a class="primary-color">
                      <h5 class="test-title">{{ medicalTest.name }}</h5>
                    </a>
                    <p class="pb-0">{{ medicalTest.description }}</p>
                    <a class="btn-link primary-color">View<i class="icofont-swoosh-right"></i></a>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
          <div *ngIf="category3.length > 0" class="profile-item">
            <h4 class="title">Infectious Diseases</h4>
            <div class="row ngx-slick-carousel-container">
              <ngx-slick-carousel
                class="profile-slider"
                [config]="slickCarouselConfig"
              >
                <div ngxSlickItem *ngFor="let medicalTest of category3">
                  <img [src]="medicalTest?.images?.[0]" alt="{{ medicalTest.name }}">
                  <div (click)="goToMedicalTestDetails(medicalTest)" class="profile-inner">
                    <div class="duration-price-container">
                      <span>Duration: <b>{{ medicalTest.duration }}</b></span>
                      <span>{{ medicalTest.price }} UGX</span>
                    </div>
                    <a class="primary-color">
                      <h5 class="test-title">{{ medicalTest.name }}</h5>
                    </a>
                    <p class="pb-0">{{ medicalTest.description }}</p>
                    <a class="btn-link primary-color">View<i class="icofont-swoosh-right"></i></a>
                  </div>
                </div>
              </ngx-slick-carousel>
            </div>
          </div>
          <div *ngIf="category4.length > 0" class="profile-item">
            <h4 class="title">Nutritional and Metabolic Panels</h4>
            <div class="row ngx-slick-carousel-container">
            <ngx-slick-carousel
              class="profile-slider"
              [config]="slickCarouselConfig"
            >
              <div ngxSlickItem *ngFor="let medicalTest of category4">
                <img [src]="medicalTest?.images?.[0]" alt="{{ medicalTest.name }}">
                <div (click)="goToMedicalTestDetails(medicalTest)" class="profile-inner">
                  <div class="duration-price-container">
                    <span>Duration: <b>{{ medicalTest.duration }}</b></span>
                    <span>{{ medicalTest.price }} UGX</span>
                  </div>
                  <a class="primary-color">
                    <h5 class="test-title">{{ medicalTest.name }}</h5>
                  </a>
                  <p class="pb-0">{{ medicalTest.description }}</p>
                  <a class="btn-link primary-color">View<i class="icofont-swoosh-right"></i></a>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
        <!-- TODO: use this if we are using groupedMedicalTests again -->
        <!-- <div *ngIf="groupedMedicalTests?.length === 0" class="no-medical-tests-found">No medical tests found</div> -->
        <div 
          *ngIf="
            category1.length === 0 && 
            category2.length === 0 &&
            category3.length === 0 &&
            category4.length === 0 " 
            class="no-medical-tests-found p-2">
            No medical tests found
        </div>
        <div
          *ngIf="
          category1.length > 0 || 
          category2.length > 0 ||
          category3.length > 0 ||
          category4.length > 0 " 
          class="text-center">
          <a routerLink="/medical-tests" class="mt-3 sigma_btn btn-sm mb-3">View More</a>
        </div>        
      </div>
    </div>
  </div>
</div>
