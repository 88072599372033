import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService, UserProfileService } from '../../services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
  forgotPasswordForm: FormGroup;
  submitted = false;
  error = '';
  success = '';
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userProfileService: UserProfileService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }

  get f() { return this.forgotPasswordForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    this.loaderService.onRequestStart();
    this.userProfileService.forgotPassword(this.forgotPasswordForm.value)
      .subscribe(
        data => {
          if (data.message) {
            this.loaderService.onRequestEnd();
            this.error = '';
            this.success = 'The password reset link has been sent to your email';
            Swal.fire({
              title: 'Check your email!',
              text: 'The password reset link has been sent to your email',
              icon: 'success',
              confirmButtonColor: 'var(--thm-base)',
            })
          }
        },
        error => {
          this.error = error;
          Swal.fire({
            title: '',
            text: 'An error occured',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          this.loaderService.onRequestEnd();
        });
  }
}
