import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import Swal from "sweetalert2";

@Component({
  selector: 'app-image-cropper',
  templateUrl: 'image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})

export class ImageCropperComponent {
  @Input() isAllowedImage: boolean = true;
  @Input() imageChangedEvent: any;
  @Input() tempCroppedImage: string;
  @Input() croppedImage: string;
  @Input() canvasRotation = 0;
  @Input() rotation?: number;
  @Input() translateH = 0;
  @Input() translateV = 0;
  @Input() scale = 1;
  @Input() aspectRatio = 1;
  @Input() resizeToWidth = 600;
  @Input() cropperMinWidth = 600;
  @Input() onlyScaleDown = true;
  @Input() roundCropper = false;
  @Input() containWithinAspectRatio = false;
  @Input() transform: ImageTransform = {};
  @Input() maintainAspectRatio = true;
  @Input() imageURL?: string;
  @Input() alignImage: 'left' | 'center' = 'center';
  @Input() showCropper = false;
  @Input() cropper = {
    x1: 0,
    y1: 0,
    x2: 600,
    y2: 400
  };
  @Output() cropImageEvent = new EventEmitter<string>();
  loading: boolean = false;

  imageCropped(event: ImageCroppedEvent) {
    this.tempCroppedImage = event.base64 ?? '';
  }  

  cropImage() {
    this.croppedImage = this.tempCroppedImage;
    this.cropImageEvent.emit(this.croppedImage);
    this.isAllowedImage = true;
    this.onCloseHandled();
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    this.cropper = {
      x1: 0,
      y1: 0,
      x2: 600,
      y2: 400
    };
    this.loading = false;
  }

  loadImageFailed() {
    Swal.fire({
      title: 'Oops...',
      text: 'Incorrect format of uploaded image',
      icon: 'error',
      confirmButtonColor: '#f37b7b'
    });
  }

  openModal() {
    const modalElement = document.querySelector('.modal');
    const modalOverlayElement = document.querySelector('.modal-overlay'); 
    if (modalElement && modalOverlayElement) {
      modalElement.classList.add('is-open');
      modalOverlayElement.classList.add('is-open');
    } else {
      console.error('Modal element or overlay not found.');
    }
  }
  
  onCloseHandled() {
    const modalElement = document.querySelector('.modal');
    const modalOverlayElement = document.querySelector('.modal-overlay');
    if (modalElement && modalOverlayElement) {
      modalElement.classList.remove('is-open');
      modalOverlayElement.classList.remove('is-open');
    } else {
      console.error('Modal element or overlay not found.');
    }
  }  

  rotateLeft() {
    this.loading = true;
    // Use timeout because rotating image is a heavy operation and will block the ui thread
    setTimeout(() => {
      this.canvasRotation--;
      this.flipAfterRotate();
    });
  }

  rotateRight() {
    this.loading = true;
    setTimeout(() => {
      this.canvasRotation++;
      this.flipAfterRotate();
    });
  }

  moveLeft() {
    this.transform = {
      ...this.transform,
      translateH: ++this.translateH
    };
  }

  moveRight() {
    this.transform = {
      ...this.transform,
      translateH: --this.translateH
    };
  }

  moveTop() {
    this.transform = {
      ...this.transform,
      translateV: ++this.translateV
    };
  }

  moveBottom() {
    this.transform = {
      ...this.transform,
      translateV: --this.translateV
    };
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
    this.translateH = 0;
    this.translateV = 0;
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn() {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  toggleAspectRatio() {
    this.aspectRatio = this.aspectRatio === 4 / 3 ? 16 / 5 : 4 / 3;
  }
}
