import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-tests',
  templateUrl: './medical-tests.component.html',
  styleUrls: ['./medical-tests.component.scss']
})
export class MedicalTestsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
