import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order, MedicalTest, PermissionId, User, Role, RoleName } from '../../models';
import { 
  MedicalTestService,
  UserProfileService,
  AuthenticationService,
  OrderService,
  DataService,
  RoleService
} from '../../services';
import Swal from 'sweetalert2';
import { first, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  users: User[] = [];
  isUsers: boolean = true;
  isMedicalTests: boolean;
  isOrders: boolean;
  isAllAdmins: boolean;
  loggedInUser: User;
  titles: string[] = [];
  tableData: User[] | MedicalTest[] | Order[];
  type: string;
  usersWithOrders: User[] = [];
  medicalTestsWithOrders: MedicalTest[] = [];
  processedTableData: User[];
  roleOptions: { label: string, value: RoleName }[] = [
    { label: 'Admin', value: RoleName.ADMIN_ONE },
    { label: 'Admin1.1', value: RoleName.ADMIN_TWO },
    { label: 'Admin1.2', value: RoleName.ADMIN_THREE },
    { label: 'Admin1.3', value: RoleName.ADMIN_FOUR }
  ];
  roles: Role[] = [];
  roleLabels: { [key: string]: string } = {};
  rolePermissions: { [key: string]: string } = {};
  isSuperAdmin: boolean;
  canViewUsers: boolean;
  canEditMedicalTests: boolean;
  canViewOrders: boolean;
  canEditOrders: boolean;
  canAddMedicalTest: boolean;

  constructor(
    private router: Router,
    private medicalTestService: MedicalTestService,
    private userProfileService: UserProfileService,
    private authenticationService: AuthenticationService,
    private orderService: OrderService,
    private dataService: DataService,
    private roleService: RoleService
  ) { }

  ngOnInit(): void {
    const currentUser = this.authenticationService.currentUser();
    if (currentUser) {
      this.loggedInUser = currentUser['user'];
      this.isSuperAdmin = this.loggedInUser.role.name === 'Admin';
      const roleId = this.loggedInUser.role_id;
      this.roleService.getSingleRole(roleId).subscribe((role: Role) => {
        const permissions = role.permissions.map(p => p.id);
        this.canViewUsers = permissions.includes(PermissionId.VIEW_USERS);
        this.canEditMedicalTests = permissions.includes(PermissionId.MANAGE_MEDICAL_TESTS);
        this.canViewOrders = permissions.includes(PermissionId.MANAGE_ORDERS);
        this.canEditOrders = permissions.includes(PermissionId.EDIT_ORDER);
        this.canAddMedicalTest = permissions.includes(PermissionId.ADD_MEDICAL_TEST);
        this.getAllUsers();
        this.loadRoles();
      });
    }
  }

  getAllMedicalTests() {
    this.titles = this.isSuperAdmin ? 
      ['#', 'Title', 'Category', 'Test price', 'Partner price', 'Action'] : 
      ['#', 'Title', 'Category', 'Test price', 'Action'];
    this.isMedicalTests = true;
    this.isUsers = false;
    this.isOrders = false;
    this.medicalTestService.getMedicalTests().pipe(
      mergeMap(medicalTests => {
        this.tableData = medicalTests as MedicalTest[];
        return this.orderService.getOrders();
      })).subscribe(orders => {
        if (orders) {
          this.medicalTestsWithOrders = orders.reduce((acc, order) => {
            if (order.medical_tests) {
              order.medical_tests.map(medicalTest => {
                if (!acc.some(item => item.id === medicalTest.id)) {
                  acc.push(medicalTest);
                }
              });
            }
            return acc;
          }, [] as MedicalTest[]);
        }
    });
  }

  getAllUsers() {
    this.titles = ['#', 'First Name', 'Last Name', 'Gender', 'Location'];
    this.isUsers = true;
    this.isMedicalTests = false;
    this.isOrders = false;
    this.userProfileService.getUsers().pipe(first()).subscribe(response => 
      this.tableData = response as User[]);
  }

  getAllAdmins() {
    this.titles = ['#', 'Name', 'Role', 'Edit Role', 'Permissions'];
    this.isUsers = false;
    this.isMedicalTests = false;
    this.isOrders = false;
    this.isAllAdmins = true;
    if (this.loggedInUser?.role?.name === RoleName.ADMIN_ONE) {
      this.userProfileService.getUsers().pipe(first()).subscribe(response => {
        this.tableData = (response as User[]).filter(user => {
          return [RoleName.ADMIN_ONE, RoleName.ADMIN_TWO, RoleName.ADMIN_THREE, RoleName.ADMIN_FOUR]
            .includes(user.role?.name as RoleName); 
        });
      });
    } else {
      this.tableData = [];
    }
  }  

  getAllOrders() {
    this.titles = ['#', 'Tests', 'Order No.', 'Name', 'Contact', 'Location', 'Status', 'Action'];
    this.isUsers = false;
    this.isMedicalTests = false;
    this.isOrders = true;
    this.orderService.getOrders().pipe(
      mergeMap(orders => {
        this.tableData = orders;
        return this.userProfileService.getUsers();
      })
    ).subscribe(users => {
        if (users) {
          this.users = users as User[];
          this.tableData.map(order => {
            const user = this.users.find(user => user.id === (order as Order).user_id);
            if (user) {
              (order as any).fullName = `${user.firstname} ${user.lastname}`;
            }
          });
        }
      });
  }

  onEditMedicalTest(medicalTest: MedicalTest) {
    this.dataService.saveMedicalTestToEdit(medicalTest);
    this.router.navigate(['add-medical-test']);
  }

  onEditOrder(order: Order) {
    this.dataService.saveOrderToEdit(order);
    this.router.navigate(['edit-order']);
  }

  onDeleteMedicalTest(medicalTest: MedicalTest) {
    if (this.medicalTestsWithOrders) {
      const isLinkedToOrders = this.medicalTestsWithOrders.some(test => test.id === medicalTest.id);  
      if (isLinkedToOrders) {
        Swal.fire({
          title: 'Medical Test Linked to Orders',
          text: 'Deleting it may affect these orders.',
          icon: 'warning',
          confirmButtonText: 'Ok',
          confirmButtonColor: 'var(--thm-base)'
        });
      } else {
        Swal.fire({
          title: '',
          text: `Are you sure you want to delete the ${medicalTest.name} medical test?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonColor: '#f37b7b',
          confirmButtonColor: 'var(--thm-base)'
        }).then((result) => {
          if (result.isConfirmed) {
            this.medicalTestService.deleteMedicalTest(medicalTest.id).pipe(first()).subscribe(
              () => {
                this.isMedicalTests = false;
                Swal.fire({
                  title: '',
                  text: 'Medical Test deleted!',
                  icon: 'success',
                  confirmButtonText: 'Ok',
                  confirmButtonColor: 'var(--thm-base)'
                }).then(() => this.getAllMedicalTests());
              },
              error => {
                Swal.fire({
                  title: 'An error occurred',
                  html: 'Please contact us at: <b>info@meshincentre.com</b>',
                  icon: 'error',
                  confirmButtonColor: '#f37b7b'
                });
                console.error('error', error);
              }
            );
          }
        });
      }
    }
  }
  
  onDeleteOrder(order: Order) {
    Swal.fire({
      title: '',
      text: `Are you sure you want to delete order number ${order.order_number}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#f37b7b',
      confirmButtonColor: 'var(--thm-base)',
    }).then((result) => {
      if (result.isConfirmed) {
        this.orderService.deleteOrder(order.id as number).pipe(first()).subscribe(
          order => {
          this.isMedicalTests = false;
          Swal.fire({
            title: '',
            text: 'Order deleted!',
            icon: 'success',
            confirmButtonText: 'Ok',
            confirmButtonColor: 'var(--thm-base)',
          }).then(() => this.getAllOrders())
        }, error => {
          Swal.fire({
            title: 'An error occurred',
            html: 'Please contact us at: <b>info@meshincentre.com</b>',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          console.error('error', error);
        });
      }
    });
  }

  editRole(user: User) {
    const swal = Swal.fire({
      title: `Edit Role`,
      html: `<select
             id="roleSelect"
             class="swal2-input"
             placeholder="Select Role"
            >
              <option value="">--Select Role--</option>
              <option value="1">Admin</option>
              <option value="2">Admin1.1</option>
              <option value="3">Admin1.2</option>
              <option value="4">Admin1.3</option>
            </select>`,
      showCancelButton: true,
      confirmButtonText: 'Submit',
      confirmButtonColor: 'var(--thm-base)',
      focusConfirm: false,
      customClass: 'results-alert open',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const roleSelectElement = Swal.getPopup()?.querySelector('#roleSelect') as HTMLSelectElement | null;
        const roleId = roleSelectElement ? roleSelectElement.value : null;
        if (roleId) {
          return roleId as string;
        } else {
          Swal.showValidationMessage('Please select a role');
          return null;
        }
      }
    }).then(result => {
      if (result.isConfirmed) {
        user.role_id = parseInt(result.value as string);
        this.userProfileService.editUser(user, user.id).pipe(first()).subscribe(() => {
          Swal.fire('Updated!', 'Role has been updated.', 'success');
          this.getAllAdmins(); 
        }, error => {
          Swal.fire('Error!', 'Role update failed.');
        });
      }
    });
  }

  loadRoles() {
    this.roleService.getRoles().subscribe(roles => {
      this.roles = roles as Role[];
      this.roles.forEach(role => {
        this.roleLabels[role.name] = this.roleOptions.find(option => option.value === role.name)?.label || 'Unknown Role';
        this.rolePermissions[role.name] = role.permissions?.map(p => p.name).join('<br>') || 'Unknown Permissions';
      });
    });
  }
}
