export interface MedicalTest {
  id: number;
  name: string | undefined;
  category: string;
  description: string;
  duration: string;
  price: string;
  partner_price?: string;
  images?: string[];
}

export interface  MedicalTestCarouselItems {
 category: string;
 groupItems: MedicalTest[];
}
