<div class="form-group">
  <label *ngIf="showLabel">{{ selectLabel }}</label>
  <select
    #select
    [multiple]="multipleType"
    class="form-control {{ selectHeightClass }}"
    [ngClass]="{'is-invalid': invalidInput}"
    [(ngModel)]="input"
    (ngModelChange)="onChange($event)"
    (blur)="onTouch()"
  >
    <option *ngFor="let selectText of selectTexts">{{ selectText }}</option>
  </select>
</div>
