<!-- Mobile Menu -->
<aside class="sigma_aside" [ngClass]="navMethod ? 'aside-open':''">
  <div class="sigma_close aside-trigger" (click)="toggleNav()">
    <span></span>
    <span></span>
  </div>
  <app-mobile-menu (closeMenu)="toggleNav()"></app-mobile-menu>
</aside>
<div class="sigma_aside-overlay aside-trigger" (click)="toggleNav()"></div>
<!-- Mobile Menu -->
<!-- Header -->
<header class="sigma_header style-5 bg-transparent shadow-none can-sticky">
  <!--div class="container">
      <div class="sigma_header-top d-none d-md-block">
          <div class="sigma_header-top-inner">
              <div class="sigma_header-top-links">
                  <ul class="sigma_header-top-nav">
                      <li>
                          <a href="#">
                              <i class="fal fa-envelope"></i>
                              example@example.com
                          </a>
                      </li>
                      <li>
                          <a href="#">
                              <i class="fal fa-map-marker-alt"></i>
                              Oakwood, Los Angeles, CA 1098
                          </a>
                      </li>
                  </ul>
              </div>
              <div class="sigma_header-top-contacts">
                  <ul class="sigma_header-top-nav">
                      <li> <a href="#"><i class="fab fa-facebook-f"></i></a>
                      </li>
                      <li> <a href="#"><i class="fab fa-twitter"></i></a>
                      </li>
                      <li> <a href="#"><i class="fab fa-linkedin-in"></i></a>
                      </li>
                      <li> <a href="#"><i class="fab fa-google"></i></a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </div-->
  <div class="container">
    <div class="sigma_header-middle pl-4 pr-4">
      <div class="navbar">
        <div class="sigma_logo-wrapper">
          <a routerLink="/" class="sigma_logo">
            <img src="../../../assets/img/go-checks-logo.png" alt="logo">
          </a>
        </div>
        <div class="d-flex align-items-center">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of navigation">
             <li class="menu-item">
                <a
                  routerLink="{{ item.link }}"
                  routerLinkActive="is-active"
                  [routerLinkActiveOptions]="{ exact: true }"
                  *ngIf="(isLoggedIn | async) ? 
                    ((isAdmin | async) ? item.link !== '/dashboard' : item.link !== '/admin') : 
                    item.link !== '/admin' && item.link !== '/dashboard'"
                >
                  {{ item.linkText }}
                </a>
              </li>
            </ng-container>
          </ul>
          <div class="sigma_header-controls style-2">
            <app-nav-links 
              [showSpan]="true" 
              [showCart]="true"
              (navToggle)="toggleNav()">
            </app-nav-links>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- Header -->
