import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { MedicalTest, Order, User } from '../../models';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AuthenticationService,
  CartService,
  DataService,
  MedicalTestService,
  OrderService,
  UserProfileService
} from '../../services';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { CountryISO, SearchCountryField, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {
  clientDashboardForm: FormGroup;
  submitted = false;
  user: User;
  isUser: boolean;
  isAdmin: boolean;
  orders: Order[] = [];
  incompleteOrder: Order;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Uganda
  ];
  cartItems: MedicalTest[] = [];
  noCart: string;
  medicalTests: MedicalTest[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    private dataService: DataService,
    private cartService: CartService,
    private medicalTestService: MedicalTestService,
    private orderService: OrderService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit(): void {
    this.medicalTestService.getMedicalTests().subscribe(
      medicalTests => {
        this.medicalTests = medicalTests as MedicalTest[];
        this.dataService.saveAllMedicalTests(this.medicalTests);
    });
    if (this.authenticationService.currentUser()) {
      this.user = this.authenticationService.currentUser()['user'];
      if (this.user) {
        this.clientDashboardForm = this.formBuilder.group({
          role: [this.user?.role],
          firstname: [this.user?.firstname, Validators.required ],
          lastname: [this.user?.lastname, Validators.required],
          email: [this.user?.email],
          telephone: [this.user?.telephone, Validators.required],
          birthDate: [this.datePipe.transform(this.user?.birth_date, 'longDate')],
          address: [this.user?.address, Validators.required],
        });   
        this.cartService.getUserCart(this.user.id).subscribe(
          response => {
            if (response.message === 'No associated cart') {
              this.noCart = 'Make your first order';
            } else {
             // @ts-ignore
             this.cartItems = response['medical_tests'];
             this.cartService.saveToCart(this.cartItems);
            }
          },
          error => {
            console.error('An unexpected error occurred', error);
          }
        );
      }
    } else {
      this.router.navigateByUrl('authenticate/login');
    }
    this.orderService.getMyOrders(this.user.id).subscribe(orders =>  this.orders = orders as Order[]);
  }

  get f() {
    return this.clientDashboardForm.controls;
  }

  goToOrder() {
    this.incompleteOrder = this.orders.find(order => order.status !== 'Order Complete') as Order;
    this.dataService.saveDataToView(this.incompleteOrder);
    this.router.navigate(['order-timeline']);
  }

  onViewDetails(order: Order) {
    this.dataService.saveDataToView(order);
    this.router.navigate(['order-details']);
  }

  onSubmit() {
    const formData = {...this.clientDashboardForm.value};
    this.submitted = true;
    if (this.clientDashboardForm.invalid) {
      return;
    }
    formData.telephone = this.f.telephone.value.e164Number;
    delete formData.birthDate;
    const formInfo = new FormData();
    this.userProfileService.editUser(formData, this.user.id)
      .pipe(first()).subscribe(response => {
        this.handleResponse(true);
      },
        error => {
          this.handleResponse(false);
        });
  }

  handleResponse(isSuccess: boolean) {
    if (isSuccess) {
      Swal.fire({
        title: 'Your Account has been updated',
        text: 'Please login to continue',
        icon: 'success',
        confirmButtonColor: '#00ACB1',
      }).then(() => {
        this.onLogout();
        this.router.navigate(['authenticate/login']);
      });
    } else {
      Swal.fire({
        title: 'An error occurred',
        html: 'Please contact us at: <b>info@go-checks.com</b> or via the contact form',
        icon: 'error',
        confirmButtonColor: '#f37b7b',
      });
    }
  }
  
  onLogout() {
    this.authenticationService.logout();
  }
}
