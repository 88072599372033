<ul class="sigma_header-controls-inner shared-links">
  <li *ngIf="!(isLoggedIn | async)" (click)="toggleNav()" class="d-sm-block ">
    <a routerLink="/authenticate/login" class="sigma_btn btn-sm">
      Login
      <i class="fal fa-sign-in ml-3"></i>
    </a>
  </li>
  <li *ngIf="(isLoggedIn | async)" class="d-sm-block ">
    <a (click)="onLogout()" class="sigma_btn btn-sm">
      Logout
      <i class="fal fa-sign-in ml-3"></i>
    </a>
  </li>
  <li *ngIf="!(isLoggedIn | async)" (click)="toggleNav()" class="d-sm-block ">
    <a routerLink="/authenticate/create-account" class="sigma_btn btn-sm register">
      Register
      <i class="fal fa-plus-square ml-3"></i>
    </a>
  </li>
  <li *ngIf="(isLoggedIn | async) && showCart" class="d-sm-block">
    <a class="sigma_btn btn-sm cart" (click)="goToCart()">
      <i class="fal fa-shopping-cart"></i>
      <span *ngIf="cartItems.length > 0" class="badge">{{ cartItems.length }}</span>
    </a>
  </li>
  <li *ngIf="showSpan" class="aside-toggle aside-trigger" (click)="toggleNav()">
    <span></span>
    <span></span>
    <span></span>
  </li>
</ul>
