import { Component, OnInit } from '@angular/core';
import { CommonData } from '../../models';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.scss']
})

export class WhyUsComponent implements OnInit {
  whyus: CommonData[] = [];
  constructor() { }

  ngOnInit(): void {
    // TODO: add real information and remove test data
    this.whyus = [
      {
        title: "User-Friendly Interface for Health Empowerment",
        icon: "flaticon-group",
        text: "Founded on the principles of accessibility and efficiency, our platform revolutionizes the traditional healthcare model by offering a user-friendly interface that allows individuals to screen, ascertain, and treat their health concerns effortlessly."
      },
      {
        title: "Timely and Reliable Diagnostics through Strategic Partnerships",
        icon: "fal fa-user-clock",
        text: "We understand the importance of timely and reliable results, and our partnership with leading medical laboratories ensures that our users receive accurate diagnostics, paving the way for informed healthcare decisions."
      }
    ];
  }

}
