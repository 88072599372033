import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService, UserProfileService } from '../../services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit, AfterViewInit {
  resetForm: FormGroup;
  submitted = false;
  error = '';
  success = '';
  isPasswordStrong: boolean;
  isPasswordHidden: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userProfileService: UserProfileService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderService.onRequestEnd();
    this.resetForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      confirmNewPassword: ['', Validators.required],
    }, {validator: this.checkPasswords});
  }

  passwordStrong(strength: boolean) {
    this.isPasswordStrong = strength;
  }

  hidePassword() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }

  /**
   * Checks for same passwords
   * @param formGroup element
   * @return notSame: boolean
   */
  checkPasswords(formGroup: FormGroup) {
    const pass = formGroup.get('password')?.value;
    const confirmPass = formGroup.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  get f() { return this.resetForm.controls; }

  /**
   * On submit form
   */
  onSubmit() {
    this.success = '';
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    this.loaderService.onRequestStart();
    this.userProfileService.resetPassword({
      password: this.f.newPassword.value,
      password_confirmation: this.f.confirmNewPassword.value,
      token: this.activatedRoute.snapshot.params['token'],
      email: this.activatedRoute.snapshot.queryParams['email']
    })
      .subscribe(
        data => {
          this.error = '';
          this.loaderService.onRequestEnd();
          Swal.fire({
            title: '',
            text: 'Password reset successfully',
            icon: 'success',
            confirmButtonColor: 'var(--thm-base)',
          }).then(() => this.router.navigateByUrl('/authenticate/login'));
        },
        error => {
          this.error = error;
          Swal.fire({
            title: 'An error occurred',
            text: 'Password not reset',
            icon: 'error',
            confirmButtonColor: '#f37b7b',
          });
          this.loaderService.onRequestEnd();
        });
  }
}
