<div class="account-pages section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-5 col-lg-6 col-md-8">
        <app-card [bodyClass]="'p-4'">
          <div body>
            <div class="text-center">
              <div class="mx-auto mb-5">
                <a routerLink="/">
                  <img src="assets/img/go-checks-logo.png" alt="" height="42"/>
                </a>
              </div>
              <h6 class="h5 mb-0 mt-4">Email verification</h6>
              <p class="text-muted mt-3 mb-3">Your email is being verified, you will be redirected shortly ...
              </p>
              <app-spinner></app-spinner>
            </div>
          </div>
        </app-card>
      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->
