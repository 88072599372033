import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, LoaderService, UtilsService } from '../../services';
import { CountryISO, SearchCountryField, PhoneNumberFormat } from 'ngx-intl-tel-input';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit, AfterViewInit {
  genderText: string[];
  createAccountForm: FormGroup;
  currentPassword: string;
  isPasswordStrong: boolean;
  selectDefaultText = '--- Select ---';
  submitted: boolean = false;
  error = '';
  loading = false;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Uganda
  ];
  isPasswordHidden: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private utilsService: UtilsService
    ) { }

  ngOnInit() {
    this.genderText = [this. selectDefaultText, 'Female', 'Male']
    this.createAccountForm = this.formBuilder.group({
      firstname: ['', [Validators.required, Validators.minLength(2)]],
      lastname: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      calendarDay: ['', [
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern('^(0[1-9]|1[0-9]|2[0-9]|3[0-1])$')
      ]],
      calendarMonth: ['', [
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern('^(0[1-9]|1[0-2])$')
      ]],
      calendarYear: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.pattern('^[0-9]*$')
      ]],
      calendarDate: ['', [Validators.required]],
      birthDate: [''],
      address: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      gender: ['', Validators.required],
      telephone: ['', Validators.required],
      termsAndConditions: ['', Validators.required]
    }, {validator: this.checkPasswords});
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }

  get f() { return this.createAccountForm.controls; }

  hidePassword() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  validateDateOfBirth(event: any, controlName: string) {
    this.utilsService.validateCalendarDate(event, controlName, this.f);
  }

  /**
   * Checks for same passwords
   * @param formGroup element
   * @return notSame: boolean
   */
  checkPasswords(formGroup: FormGroup) {
    const pass = formGroup.get('password')?.value;
    const confirmPass = formGroup.get('confirmPassword')?.value;
    return pass === confirmPass ? null : { notSame: true };
  }

  passwordStrong(strength: boolean) {
    this.isPasswordStrong = strength;
  }

  onSubmit() {
    this.submitted = true;
    this.f.birthDate.setValue(this.f.calendarDate.value);
    const formData = {...this.createAccountForm.value};
    formData.gender = this.f.gender.value[0];
    formData.telephone = this.f.telephone.value.e164Number;
    if (this.createAccountForm.invalid ||
      !this.f.termsAndConditions.value ||
      !this.isPasswordStrong) {
      return;
    }
    this.authenticationService.register(formData)
      .subscribe(
        data => {
          Swal.fire({
            title: '',
            text: 'Account is created!',
            icon: 'success',
            confirmButtonColor: 'var(--thm-base)',
          }).then(() => {
            const response: any = data;
            if (response.message === 'User created') {
              this.sendVerification(response.id);
            }
          });
        },
      error => {
       this.error = error;
       const popUpConfig: SweetAlertOptions = {
        title: 'An error occurred',
        html: 'Please contact us at: <b>info@gochecks.com</b> or via the contact form',
        icon: 'error',
        confirmButtonColor: '#f37b7b',
        confirmButtonText: 'Ok',
        showCancelButton: false,
        cancelButtonColor: '#f37b7b',
        cancelButtonText: 'Try Again'
      };
      if (error[0] === 'The email has already been taken.') {
        popUpConfig.title = 'Email in use';
        popUpConfig.html = 'The email you entered is already registered. Please use a different email.';
        Swal.fire(popUpConfig);
      } else {
        this.notifyUser(popUpConfig);
      }
    });
  }

  notifyUser(popUpConfig: SweetAlertOptions) {
    Swal.fire(popUpConfig).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('contact');
      }
    });
  }

  sendVerification(id: number) {
    this.authenticationService.sendVerification(id)
      .subscribe(response => {
        this.router.navigate(['/authenticate/confirm']);
      },error => {
        Swal.fire('Oops...', 'Sending activation code failed', 'error');
      });
  }
}
