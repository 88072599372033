<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9 service-details">
  <div class="section sigma_post-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="order-timeline">
            <h4 class="order-title">{{ 'Order ' + (order?.order_number) + ' Progress' }}</h4>
            <div class="timeline"
              *ngFor='let entry of orderStatus; let i = index'
              [ngClass]="{ 'grayed-out': !completedStatuses[i] }">
              <div>
                <div class="container right">
                  <div class="content">
                    <h5>{{ order.executed_at ? (order.executed_at[i] || 'Pending') : 'Pending' }}</h5>
                    <h6>{{ entry.status }}</h6>
                    <p>{{ entry.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

