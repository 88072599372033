import {
  Component,
  Input,
  Output,
  ContentChild,
  TemplateRef,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

export class TableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() tableTitle: string;
  @Input() tableColumnTitles: string[];
  @Input() tableData: any;
  @Input() filterColumn: number;
  @Input() rowColourClass: string;
  @Output() filteredData = new EventEmitter<any>();
  @ContentChild('table', {static: false}) tableTemplateRef: TemplateRef<any>;
  @ViewChild(DataTableDirective, {static: false}) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  isTableReady: boolean;

  ngOnInit() {
    this.dtOptions = {
      responsive: true,
      // TODO: Shall uncomment if it is required to initialize the table
      /*initComplete: () => {
        this.isTableReady = true;
      },*/
      destroy: true,
      retrieve: true
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.tableData) {
      this.dtTrigger.next();
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
