<div class="account-pages section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10">
        <app-card [bodyClass]="'p-0'">
          <div body>
            <div class="row">
              <div class="col-6 gochecks-form">
                <div class="mx-auto mb-5">
                  <a routerLink="/account/login">
                    <img src="assets/img/go-checks-logo.png" alt="" height="42"/>
                  </a>
                </div>
                <h6 class="h5 mb-0 mt-4">Reset Password</h6>
                <p class="text-muted mt-1 mb-5">
                  {{ success ? success : 
                  'Enter your email address and we will send you an email with instructions to reset your password.' }}
                </p>
                <form
                  action="#"
                  class="authentication-form"
                  name="resetForm"
                  [formGroup]="forgotPasswordForm"
                  (ngSubmit)="onSubmit()"
                  novalidate>

                  <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                  <ngb-alert type="success" *ngIf="success" [dismissible]="false">{{ success }}</ngb-alert>

                  <div class="form-group" *ngIf="!success">
                    <div class="row">
                      <div class="col-md-12">
                        <app-input-text
                          [textLabel]="'Email: *'"
                          [type]="'email'"
                          [invalidInput]="submitted && f.email.invalid"
                          placeholder="hello@gmail.com"
                          formControlName="email"
                          [formGroup]="forgotPasswordForm"
                        ></app-input-text>
                        <div *ngIf="submitted && f.email.errors" class="is-invalid">
                          <div *ngIf="f.email.errors.required">Email is required</div>
                          <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0 text-center" *ngIf="!success">
                    <button class="btn btn-primary btn-block authentication-btn" type="submit"> Submit</button>
                  </div>
                </form>
              </div>
              <div class="col-6 bzmonitor-sideimage">
                <div class="auth-page-sidebar">
                  <div class="overlay"></div>
                  <div class="auth-user-testimonial">
                    <p class="font-size-24 font-weight-bold text-white mb-1">Order For A Test!</p>
                    <p class="lead">"It's easy and effective. Everybody loves it!"
                    </p>
                    <p>- Go-Checks</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">Back to <a routerLink="/authenticate/login"
                                             class="secondary-color font-weight-bold ml-1">Login</a></p>
            <!-- <p class="mesh-product-text">Product of <a href="https://meshincentre.com">Mesh Innovation Centre</a></p> -->
          </div> <!-- end col -->
        </div>
        <!-- end row -->
      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->
