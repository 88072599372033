<h4>{{tableTitle}}</h4>
<table
  datatable
  class="row-border hover"
  [dtOptions]="dtOptions"
  [dtTrigger]="dtTrigger"
>
<!--  TODO: add this back to the table if nedded before the table initializes
  [ngClass]="{'is-table-ready': isTableReady && tableData}" -->
  <thead>
    <tr>
      <th *ngFor="let column of tableColumnTitles">{{column}}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of tableData; let i = index;">
      <ng-container
        [ngTemplateOutlet]="tableTemplateRef"
        [ngTemplateOutletContext]="{$implicit: data, index: i}"
      ></ng-container>
    </tr>
  </tbody>
</table>
