<div class="account-pages section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10">
        <app-card [bodyClass]="'p-0'">
          <div body>
            <div class="row">
              <div class="col-6 gochecks-form">
                <div class="mx-auto mb-2">
                  <a routerLink="/authenticate/login">
                    <img src="assets/images/bzmonitor-logo.png" alt="" height="42" />
                    <h4 class="d-inline align-middle text-logo">Go-Checks</h4>
                  </a>
                </div>
                <h6 class="h6 mb-0">Create your account</h6>
                <form
                  class="authentication-form"
                  [formGroup]="createAccountForm"
                  (ngSubmit)="onSubmit()"
                  novalidate>
                  <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                  <div class="row">
                    <div class="col-md-6">
                      <app-input-text
                        [textLabel]="'Firstname: *'"
                        [type]="'text'"
                        [invalidInput]="submitted && f.firstname.invalid"
                        formControlName="firstname"
                        [formGroup]="createAccountForm"
                      >
                      </app-input-text>
                      <div *ngIf="submitted && f.firstname.errors" class="is-invalid">
                        <div class="f.firstname.errors.required"> Firstname required</div>
                        <div
                          *ngIf="f.firstname.errors.minlength"
                        >
                          Must be at least 2 characters
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <app-input-text
                        [textLabel]="'Lastname: *'"
                        [type]="'text'"
                        [invalidInput]="submitted && f.lastname.invalid"
                        formControlName="lastname"
                        [formGroup]="createAccountForm"
                      >
                      </app-input-text>
                      <div *ngIf="submitted && f.lastname.errors" class="is-invalid">
                        <div *ngIf="f.lastname.errors.required">Lastname required</div>
                        <div
                          *ngIf="f.lastname.errors.minlength"
                        >
                          Must be at least 2 characters
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <app-input-text
                        [textLabel]="'Email: *'"
                        [type]="'email'"
                        [invalidInput]="submitted && f.email.invalid"
                        formControlName="email"
                        [formGroup]="createAccountForm"
                      >
                      </app-input-text>
                      <div *ngIf="submitted && f.email.errors" class="is-invalid">
                        <div *ngIf="f.email.errors.required">Email required</div>
                        <div *ngIf="f.email.errors.email ||
                         f.email.errors.pattern"
                        >Email must be a valid email address</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <app-input-text
                        [textLabel]="'Address: *'"
                        [type]="'text'"
                        [invalidInput]="submitted && f.address.invalid"
                        formControlName="address"
                        [formGroup]="createAccountForm"
                      >
                      </app-input-text>
                      <div *ngIf="submitted && f.address.errors" class="is-invalid">
                        <div *ngIf="f.address.errors.required">Address required</div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <app-input-text
                        [textLabel]="'Password: *'"
                        [type]="isPasswordHidden ? 'password' : 'text'"
                        [invalidInput]="submitted && f.password.invalid"
                        formControlName="password"
                        [formGroup]="createAccountForm"
                      ></app-input-text>
                      <i
                        *ngIf="f.password.value"
                        [ngClass]="isPasswordHidden ? 'fal fa-eye' : 'fal fa-eye-slash'"
                        (click)="hidePassword()">
                      </i>
                      <div *ngIf="submitted && f.password.errors" class="is-invalid">
                        <div *ngIf="f.password.errors.required">Password required</div>
                        <div *ngIf="f.password.errors.minlength">Must be at least 6 characters</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <app-input-text
                        [textLabel]="'Confirm Password: *'"
                        [type]="isPasswordHidden ? 'password' : 'text'"
                        [invalidInput]="submitted && (f.confirmPassword.invalid || createAccountForm.hasError('notSame'))"
                        formControlName="confirmPassword"
                        [formGroup]="createAccountForm"
                      ></app-input-text>
                      <i
                        *ngIf="f.confirmPassword.value"
                        [ngClass]="isPasswordHidden ? 'fal fa-eye' : 'fal fa-eye-slash'"
                        (click)="hidePassword()">
                      </i>
                      <div *ngIf="submitted && (f.confirmPassword.invalid || createAccountForm.hasError('notSame'))" class="is-invalid">
                        Passwords should match
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <app-password-strength
                        [barLabel]="'Password Strength'"
                        [passwordToCheck]="f.password.value"
                        (passwordStrong)="passwordStrong($event)"
                      ></app-password-strength>
                      <div class="is-invalid" *ngIf="submitted && !isPasswordStrong">Password not strong</div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Phone no.: *</label>
                      <ngx-intl-tel-input
                        [preferredCountries]="preferredCountries"
                        [enableAutoCountrySelect]="true"
                        [enablePlaceholder]="true"
                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                        [selectedCountryISO]="CountryISO.Uganda"
                        [phoneValidation]="true"
                        [customPlaceholder]="'772123456'"
                        name="phone"
                        formControlName="telephone">
                      </ngx-intl-tel-input>
                      <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
                        <div *ngIf="f.telephone.errors.required">Telephone required</div>
                        <div *ngIf="f.telephone.errors?.validatePhoneNumber">Invalid Number</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <app-select
                        [showLabel]="true"
                        [selectLabel]="'Gender: *'"
                        [selectTexts]="genderText"
                        [invalidInput]="submitted && (f.gender.invalid || f.gender.value[0] === selectDefaultText)"
                        formControlName="gender"
                        [formGroup]="createAccountForm"
                      ></app-select>
                      <div *ngIf="submitted && (f.gender.errors ||
                        f.gender.value[0] === selectDefaultText)" class="is-invalid">
                        Gender required
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <label>Date of birth: *</label>
                      <div class="date-container">
                        <div class="date">
                          <div class="date-label">
                            Day
                          </div>
                          <div>
                            <input
                              data-test="birth-date-day"
                              type="text"
                              class="form-control day"
                              placeholder="DD"
                              formControlName="calendarDay"
                              (paste)="$event.preventDefault()"
                              (input)="validateDateOfBirth($event, 'calendarDay')"
                            >
                          </div>
                        </div>
                        <span class="date-separator"> / </span>
                        <div class="date">
                          <div class="date-label">
                            Month
                          </div>
                          <div>
                            <input
                              data-test="birth-date-month"
                              type="text"
                              class="form-control month"
                              placeholder="MM"
                              formControlName="calendarMonth"
                              (paste)="$event.preventDefault()"
                              (input)="validateDateOfBirth($event, 'calendarMonth')"
                            >
                          </div>
                        </div>
                        <span class="date-separator"> / </span>
                        <div class="date">
                          <div class="date-label">
                            Year
                          </div>
                          <div>
                            <input
                              data-test="birth-date-year"
                              type="text"
                              class="form-control year"
                              placeholder="YYYY"
                              formControlName="calendarYear"
                              (paste)="$event.preventDefault()"
                              (input)="validateDateOfBirth($event, 'calendarYear')"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <app-checkbox
                        formControlName="termsAndConditions"
                        [formGroup]="createAccountForm"
                      >
                        <span>
                          I have read and accept the
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            routerLink="/terms"
                          >
                            Terms and Conditions
                          </a>
                          of Go-Checks *
                        </span>
                      </app-checkbox>
                      <div
                        class="is-invalid"
                        *ngIf="submitted && !f.termsAndConditions.value"
                      >
                        Terms required
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-4">
                    <button class="btn btn-primary btn-block authentication-btn" type="submit"> Sign Up
                    </button>
                  </div>
                </form>
              </div>
              <div class="col-6 authentication-sideimage">
                <div class="auth-page-sidebar">
                  <div class="overlay"></div>
                  <div class="auth-user-testimonial">
                    <p class="text-one font-weight-bold text-white mb-1">Order For A Test Online!</p>
                    <p class="text-two">"It's quick and convenient"
                    </p>
                    <p>- Go-Checks</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">Already have account? <a routerLink="/authenticate/login"
                class="secondary-color font-weight-bold ml-1">Login</a></p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->
      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->
