import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, RoleService } from '../services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission, Role } from '../models';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private roleService: RoleService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const currentUser = this.authenticationService.currentUser();
    if (currentUser) {
      return this.roleService.getSingleRole(currentUser.user.role_id).pipe(
        map((role: Role) => {
          const permissionIds = role?.permissions?.map((p: Permission) => p.id) || [];
          if (role && route.data.roles && route.data.roles.indexOf(role.name) === -1) {
            this.router.navigate(['authenticate/home']);
            return false;
          }
          if (route.data.permissions) {
            const hasPermission = route.data.permissions
              .every((permissionId: number) => permissionIds.includes(permissionId));
            if (!hasPermission) {
              this.router.navigate(['authenticate/home']);
              return false;
            }
          }

          return true;
        })
      );
    }
    // not logged in so redirect to login page with the return url
    this.router.navigate(
      ['authenticate/login'], { queryParams: { returnUrl: state.url } });
    return new Observable(observer => observer.next(false));
  }
}
