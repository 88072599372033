<div class="form-group {{ checkboxInlineClass }}">
  <label *ngIf="checkboxLabel" class="title-label">{{ checkboxLabel }}</label>
    <label>
      <input
        type="checkbox"
        [(ngModel)]="input"
        (ngModelChange)="onChange($event)"
        (blur)="onTouch()"
      ><ng-content></ng-content>
      <span class="checkmark"></span>
    </label>
</div>
