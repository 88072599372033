import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalise',
})
export class capitalisePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }

    return value.split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join(' ');
  }
}