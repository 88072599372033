import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EmailVerificationComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    ) {}

  ngOnInit() {
    this.authenticationService.verifyEmail(this.BuildQueryURL({
      id: this.activatedRoute.snapshot.params['id'],
      token: this.activatedRoute.snapshot.params['token'],
      expires: this.activatedRoute.snapshot.queryParams['expires'],
      signature: this.activatedRoute.snapshot.queryParams['signature']}))
        .subscribe(response => {
          this.router.navigateByUrl('/authenticate/login');
        },
        error => {
           Swal.fire(
             'Email verification failed!',
             'Please contact us at: <b>info@go-checks.com</b>',
             'error'
           ).then(() =>
           this.router.navigateByUrl('/authenticate/create-account'));
        });
  }

  /**
   * Builds the query string
   */
  BuildQueryURL(data: {id: string, token: string, expires: string, signature: string}) {
    return `email/verify/${data.id}/${data.token}?expires=${data.expires}&signature=${data.signature}`;
  }
}
