<app-breadcrumbs></app-breadcrumbs>
<div class="dashboard-area pt-5">
  <div class="container">
    <form
      [formGroup]="clientDashboardForm"
      (ngSubmit)="onSubmit()"
      (keydown.enter)="$event.preventDefault()"
    >
      <div *ngIf="user" class="row align-items-center">
        <div class="col-lg-3">
          <div class="dashboard-img">
            <div class="no-acc-image mb-3">
              {{user.firstname.charAt(0).toUpperCase() + user.lastname.charAt(0).toUpperCase()}}
            </div>        
            <div>{{ user.firstname + ' ' + user.lastname }}</div>
            <p>{{ user.address }}</p>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="dashboard-nav">
            <ul class="nav nav-pills mb-3 mt-2" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="sigma_btn btn-sm"
                  id="pills-home-tab"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                  (click)="goToOrder()"
                >
                  Current Order
                </a>
              </li>

              <li class="nav-item ml-5">
                <a
                  class="sigma_btn btn-sm"
                  id="pills-profile-tab"
                  data-toggle="pill"
                  routerLink="/settings"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Change Password
                </a>
              </li>

              <li class="nav-item ml-5">
                <a
                  class="sigma_btn btn-sm"
                  id="pills-logout-tab"
                  data-toggle="pill"
                  role="tab"
                  aria-controls="pills-logout"
                  aria-selected="false"
                  (click)="onLogout()"
                >
                  Logout
                </a>
              </li>
            </ul>
          </div>

          <div class="orders">
            <div class="orders-wrap">
              <div class="orders-left">
                <h5>Orders History</h5>
              </div>
            </div>
            <div class="row title-container">
              <div class="col-lg-4">
                <div class="form-group">
                  <p>Date</p>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <p>Status</p>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="form-group">
                  <p>Action</p>
                </div>
              </div>
            </div>
            <ul>
              <li *ngFor="let order of orders; let i = index;">
                <div *ngIf="orders.length > 0" class="row">
                  <div class="col-lg-4">
                    <div class="form-group">
                      <div class="mobile-element title">{{(i + 1) + '. ' + order.created_at}}</div>
                      <label>
                        <span>{{(i + 1) + '. '}}</span>
                        {{order.created_at | date}}
                      </label>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group">
                      <label>
                        {{order.status}}
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <button (click)="onViewDetails(order)" class="sigma_btn btn-sm view-btn mt-2 mb-2">
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <div *ngIf="orders.length === 0" class="row no-orders-found">
                <a *ngIf="noCart" routerLink="/medical-tests">
                  {{noCart ? noCart : 'No orders found'}}
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="dashboard-form">
            <h5>Basic Information</h5>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>First Name</label>
                    <i class="fal fa-user"></i>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Jane"
                      [attr.disabled]="true"
                      formControlName="firstname"
                    >
                    <div *ngIf="submitted && f.firstname.errors" class="is-invalid">
                      <div *ngIf="f.firstname.errors.required">First name required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Last Name</label>
                    <i class="fal fa-user"></i>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Doe"
                      [attr.disabled]="true"
                      formControlName="lastname"
                    >
                    <div *ngIf="submitted && f.lastname.errors" class="is-invalid">
                      <div *ngIf="f.lastname.errors.required">Last name required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Email</label>
                    <i class="fal fa-at"></i>
                    <input 
                      type="email" 
                      class="form-control"
                      placeholder="email"
                      [attr.disabled]="true"
                      formControlName="email"
                    >
                    <div *ngIf="submitted && f.email.errors" class="is-invalid">
                      <div *ngIf="f.email.errors.required">Email required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Date of birth</label>
                    <i class="fal fa-calendar-alt"></i>
                    <input
                      type="text"
                      class="form-control"
                      [attr.disabled]="true"
                      formControlName="birthDate"
                    >
                    <div *ngIf="submitted && f.birthDate.errors" class="is-invalid">
                      <div *ngIf="f.birthDate.errors.required">Birth Date required</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Phone No.</label>
                    <i class="fal fa-phone"></i>
                    <ngx-intl-tel-input
                      [preferredCountries]="preferredCountries"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [selectedCountryISO]="CountryISO.Uganda"
                      [phoneValidation]="true"
                      [customPlaceholder]="'772123456'"
                      name="phone"
                      formControlName="telephone">
                    </ngx-intl-tel-input>
                    <div *ngIf="submitted && f.telephone.errors" class="is-invalid">
                      <div *ngIf="f.telephone.errors.required">Telephone required</div>
                      <div *ngIf="f.telephone.errors?.validatePhoneNumber">Invalid Number</div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>Address</label>
                    <i class="fal fa-map-marker-alt"></i>
                    <input 
                      type="text" 
                      class="form-control"
                      placeholder="Kampala"
                      formControlName="address"
                    >
                    <div *ngIf="submitted && f.address.errors" class="is-invalid">
                      <div *ngIf="f.address.errors.required">Address required</div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div *ngIf="submitted && clientDashboardForm.invalid" class="alert alert-danger" role="alert">
            <i class="icofont-warning-alt"></i>
            The form contains one or more invalid input.
          </div>
          <div class="text-left">
            <button type="submit" class="sigma_btn btn-sm mt-3 mb-5"> 
              Save 
              <i class="fal fa-arrow-right ml-3"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
