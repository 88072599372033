import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services';

@Component({
  selector: 'app-payment-abort',
  templateUrl: './payment-abort.component.html',
  styleUrls: ['./payment-abort.component.scss']
})

export class PaymentAbortComponent implements OnInit {
  constructor (
    private dataService: DataService,
  ) { }

  ngOnInit() {
    this.dataService.clear();
  }
}
