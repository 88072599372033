import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup
} from '@angular/forms';
import { DataService } from '../../services';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  searchForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService
    ) { }
  
  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchInput: [''],
      duration: ['']
    });
  }

  get f() {
    return this.searchForm.controls;
  }

  onCleared(className: string, controlName: string) {
    const inputElement = document.querySelector(`.${className}`) as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
      this.searchForm.get(controlName)?.setValue('');
    }
  }

  onSubmit() {
    this.dataService.saveSearchCriteria(this.searchForm.value);
  }
}
