import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Order } from '../models';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class OrderService {
  headers: HttpHeaders = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });

  constructor(private http: HttpClient) {}

   /**
   * Gets the orders
   * @return server response
   */
   getOrders() {
    return this.http.get(
      'orders')
      .pipe(map(response => response as Order[]));
  }

  /**
   * Gets the total number of orders
   * @return server response
   */
  getTotalOrders() {
    return this.http.get(
      'total-orders')
      .pipe(map(response => response));
  }

  /**
   * Gets the specific order
   * @return server response
   */
  getSingleOrder(id: number) {
    return this.http.get(
      `order/${id}`)
      .pipe(map(response => response));
  }

  /**
   * Get my orders
   * 
   * @param userId
   */
  getMyOrders(userId: number) {
    return this.http.get(
      `user-orders/${userId}`,
      { headers: this.headers })
      .pipe(map(response => response));
  }

  /**
   * Adds item to order
   * @param formData
   */
  addOrder(formData: FormData) {
    return this.http.post(
      'order', formData)
      .pipe(map(response => response));
  }

  /**
   * Updates order
   * @param orderId
   * @param formData
   */
  updateOrder(orderId: number, formData: FormData) {
    return this.http.post(`order/${orderId}`, formData);
  }

  /**
   * Deletes the order from the db
   *
   * @param id to edit
   * @returns Observable<any>
   */
  deleteOrder(id: number) {
    return this.http.delete(`order/${id}`);
  }
}
