<div class="account-pages section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-10">
        <app-card [bodyClass]="'p-0'">
          <div body>
            <div class="row">
              <div class="col-6 gochecks-form">
                <div class="mx-auto mb-5">
                  <a routerLink="/account/login">
                    <img src="assets/img/go-checks-logo.png" alt="" height="42" />
                  </a>
                </div>
                <h6 class="h5 mb-0 mt-4">Reset Password</h6>
                <p class="text-muted mt-1 mb-1">
                  Create your new password.
                </p>
                <form
                  action="#"
                  class="authentication-form"
                  name="resetForm"
                  [formGroup]="resetForm"
                  (ngSubmit)="onSubmit()"
                  novalidate>
                  <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <app-input-text
                          [textLabel]="'New Password: *'"
                          [type]="isPasswordHidden ? 'password' : 'text'"
                          [invalidInput]="submitted && f.newPassword.invalid"
                          formControlName="newPassword"
                          [formGroup]="resetForm"
                        ></app-input-text>
                        <i
                          *ngIf="f.newPassword.value"
                          [ngClass]="isPasswordHidden ? 'fal fa-eye' : 'fal fa-eye-slash'"
                          (click)="hidePassword()">
                        </i>
                        <div *ngIf="submitted && f.newPassword.errors" class="is-invalid">
                          <div *ngIf="f.newPassword.errors.required">New password is required</div>
                          <div
                            *ngIf="f.newPassword.errors.minlength"
                          >
                            Must be at least 8 characters
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <app-password-strength
                          [barLabel]="'Password Strength'"
                          [passwordToCheck]="f.newPassword.value"
                          (passwordStrong)="passwordStrong($event)"
                        ></app-password-strength>
                        <div class="is-invalid" *ngIf="submitted && !isPasswordStrong">Password not strong</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <app-input-text
                          [textLabel]="'Confirm new Password: *'"
                          [type]="isPasswordHidden ? 'password' : 'text'"
                          [invalidInput]="submitted && f.confirmNewPassword.invalid"
                          formControlName="confirmNewPassword"
                          [formGroup]="resetForm"
                        ></app-input-text>
                        <i
                          *ngIf="f.confirmNewPassword.value"
                          [ngClass]="isPasswordHidden ? 'fal fa-eye' : 'fal fa-eye-slash'"
                          (click)="hidePassword()">
                        </i>
                        <div
                          class="is-invalid"
                          *ngIf="submitted && (f.confirmNewPassword.invalid || resetForm.hasError('notSame'))">
                          Passwords should match
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0 text-center" *ngIf="!success">
                    <button class="btn btn-primary btn-block authentication-btn" type="submit"> Submit</button>
                  </div>
                </form>
              </div>
              <div class="col-6 authentication-sideimage">
                <div class="auth-page-sidebar">
                  <div class="overlay"></div>
                  <div class="auth-user-testimonial">
                    <p class="text-one font-weight-bold text-white mb-1">Order For A Test Online!</p>
                    <p class="text-two">"It's quick and convenient"</p>
                    <p>- Go-Checks</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-card>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <p class="text-muted">Back to <a routerLink="/authenticate/login"
                class="secondary-color font-weight-bold ml-1">Login</a></p>
          </div> <!-- end col -->
        </div>
        <!-- end row -->
      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->
