import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService, AuthenticationService } from '../../services';
import { NavigationDetails, User } from '../../models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent implements OnInit {
  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
  navigation: NavigationDetails[] = [];
  isLoggedIn: Observable<boolean>;
  isAdmin: Observable<boolean>;
  user: User;
  navMethod: boolean = false; 

  constructor(
    private dataService: DataService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.navigation = this.dataService.getNavigationDetails();
    this.isLoggedIn = this.authenticationService.isLoggedIn();
    this.isAdmin = this.authenticationService.isAdminLoggedIn();
  }

  closeMobileMenu() {
    this.closeMenu.emit();
  }
}
