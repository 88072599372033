export enum PermissionId {
  VIEW_USERS = 1,
  MANAGE_MEDICAL_TESTS = 2,
  MANAGE_ORDERS = 3,
  EDIT_ORDER = 4,
  ADD_MEDICAL_TEST = 5
}

export interface Permission {
  id: number;
  name: string;
}
