<app-breadcrumbs></app-breadcrumbs>
<div class="permissions-area pt-5">
  <div class="container">
    <h4>Manage Permissions</h4>
    <div class="row align-items-center">
      <div *ngFor="let role of adminRoles" class="col-lg-6 mb-3">
        <label>{{ getRoleName(role) }}</label>
        <select class="form-control" multiple (change)="onRoleChange($event, role)">
          <option 
            *ngFor="let permission of allPermissions" 
            [value]="permission.id.toString()" 
            [ngClass]="{'highlight': isSelected(role, permission.id.toString())}" 
          >
            {{ permission.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mt-3 pb-3">
      <div class="col-lg-12">
        <h5>Selected Permissions:</h5>
        <div *ngFor="let role of roles" class="role-permissions">
          <strong>{{ role.name }}</strong>:
          <div class="chips-container">
            <div *ngFor="let permission of role.permissions" class="chip">
              {{ permission.name }}
              <span class="closebtn" (click)="removePermission(role.name, permission.id.toString())">&times;</span>
            </div>
          </div>
        </div>
        <div *ngIf="roles.length === 0">
          No permissions selected.
        </div>
      </div>
    </div>
  </div>
</div>
