import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Role } from '../models';

@Injectable({providedIn: 'root'})
export class RoleService {
  constructor(
    private http: HttpClient,
  ) {}

  /**
   * Gets the roles
   * @return server response
   */
  getRoles() {
    return this.http.get(
        'roles')
        .pipe(map(response => response as Role[]));
  }

  /**
   * Gets One role
   */
  getSingleRole(id: number) {
    return this.http.get(
      `role/${id}`)
      .pipe(map(response => response as Role));
  }

  /**
   * Updates the roles in the db
   *
   * @param roleData to edit
   * @param id to role
   * @returns Observable<any>
   */
  editRoles(id: number, roleData: FormData) {
    return this.http.post(`role/${id}`, roleData);
  }
}
