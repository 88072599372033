<div class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Crop image (max size 600 x 400)</div>
        <button 
          type="button" 
          class="close" 
          data-dismiss="modal" 
          aria-label="Close" 
          (click)="onCloseHandled()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="crop-btn">
          <button type="button" class="btn btn-primary crop" (click)="cropImage()">
            Crop
          </button>
        </div>
        <image-cropper 
          *ngIf="!isAllowedImage" 
          [imageChangedEvent]="imageChangedEvent" 
          [imageURL]="imageURL"
          [maintainAspectRatio]="maintainAspectRatio" 
          [containWithinAspectRatio]="containWithinAspectRatio"
          [aspectRatio]="aspectRatio" 
          [resizeToWidth]="resizeToWidth" 
          [cropperMinWidth]="cropperMinWidth"
          [onlyScaleDown]="onlyScaleDown" 
          [roundCropper]="roundCropper" 
          [cropper]="cropper"
          [canvasRotation]="canvasRotation" 
          [transform]="transform" 
          [alignImage]="alignImage"
          [style.display]="showCropper ? null : 'none'" 
          format="jpeg"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()" 
          (cropperReady)="cropperReady($event)"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
    </div>
  </div>
</div>