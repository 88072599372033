import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterDetails, MedicalTest, OtherServices, Search } from '../../models';
import { DataService } from '../../services';

@Component({
  selector: 'app-service-sidebar',
  templateUrl: './service-sidebar.component.html',
  styleUrls: ['./service-sidebar.component.scss']
})

export class ServiceSidebarComponent implements OnInit {
  @Input() medicalTest: MedicalTest;
  @Input() cartItems: MedicalTest[] = [];
  @Input() otherService: OtherServices;
  @Input() isShowAddCart: boolean;
  categories: FilterDetails[] = [];
  medicalTests: MedicalTest[] = [];
  categoryCounts: { [key: string]: number } = {};

  constructor( 
    private dataService: DataService,
    private router: Router,
   ) {}

  ngOnInit(): void {
    this.categories = this.dataService.getFilterDetails();
    this.dataService.getAllMedicalTests().subscribe(
      medicalTests => {
        this.medicalTests = medicalTests;
        this.categoryCounts = this.getMedicalTestCountsByCategory(medicalTests);
      });
  }

  getMedicalTestCountsByCategory(medicalTests: MedicalTest[]): { [key: string]: number } {
    return medicalTests.reduce((counts: { [key: string]: number }, test: MedicalTest) => {
      counts[test.category] = (counts[test.category] || 0) + 1;
      return counts;
    }, {});
  }

  addToCart(medicalTest: MedicalTest) {
    this.dataService.addToCart(medicalTest, this.cartItems);
  }

  filterMedicalTests(searchCriteria: Search) {
    this.dataService.saveSearchCriteria(searchCriteria);
    this.router.navigateByUrl('medical-tests');
  }
}
