<div class="section">
	<div class="container">
		<div class="row align-items-center mb-5">
			<div class="col-lg-4">
				<div class="section-title">
					<span class="subtitle">Work Process</span>
					<h3 class="title mb-0">How it Works?</h3>
				</div>
			</div>
			<div class="col-lg-5">
				<p class="mb-0">
          Our commitment goes beyond mere transactions; we are dedicated to empowering individuals with the
          knowledge they need to prioritize their well-being. Whether you are seeking routine check-ups, specific
          screenings, or monitoring chronic conditions, Go-checks is your trusted partner on the journey to better
          health. Join us in embracing a future where healthcare is not just a necessity but an accessible and
          personalized experience for everyone.
        </p>
			</div>
			<div class="col-lg-3 text-lg-right">
				<a routerLink="/medical-tests" class="sigma_btn mt-4 mt-lg-0">Order Test</a>
			</div>
		</div>
		<div class="row sigma_info-wrapper style-25">
			<!-- Data -->
			<div class="col-lg-4 col-md-6" *ngFor="let item of workprocess;let i = index;">
				<div class="sigma_info style-25 d-block d-xl-flex">
					<div class="sigma_info-title">
						<span class="sigma_info-icon">
							<i [class]="item.icon"></i>
						</span>
					</div>
					<div class="sigma_info-description mt-4 mt-xl-0">
						<h5 [innerHtml]="item.title"></h5>
						<p>{{item.text}}</p>
						<span class="steps">Step {{1+i}}</span>
						<span class="pulsive-dot"></span>
					</div>
				</div>
			</div>
			<!-- Data -->
		</div>
	</div>
</div>
