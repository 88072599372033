import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OtherServices } from '../../models';
import { DataService } from '../../services';

@Component({
  selector: 'app-single-service-details',
  templateUrl: './single-service-details.component.html',
  styleUrls: ['./single-service-details.component.scss']
})

export class SingleServiceDetailsComponent implements OnInit {
  otherService: OtherServices;
  selectedImageIndex: number = 0;
  selectedImage: string = '';
  isShowAddCart: boolean = false;

  constructor(
    private dataService: DataService,
    private router: Router,
    ) {
  }

  ngOnInit(): void {
    this.dataService.getDataToView().subscribe(dataToView => {
      if (dataToView !== null) {
        this.otherService = dataToView as OtherServices;
      } else {
        this.router.navigate(['/our-services']);
      }
    });
  }

  selectImage(index: number) {
    if (this.otherService && this.otherService.images && this.otherService.images.length > 1) {
      this.selectedImageIndex = index;
      this.selectedImage = this.otherService.images[index];
    }
  }

}
