export * from './home/home.component';
export * from './about/about.component';
export * from './settings/settings.component';
export * from './our-services/our-services.component';
export * from './faq/faq.component';
export * from './medical-tests/medical-tests.component';
export * from './medical-test-details/medical-test-details.component';
export * from './add-order/add-order.component';
export * from './order-details/order-details.component';
export * from './order-timeline/order-timeline.component';
export * from './add-medical-test/add-medical-test.component';
export * from './contact/contact.component';
export * from './cart/cart.component';
export * from './dashboard/dashboard.component';
export * from './admin/admin.component';
export * from './terms/terms.component';
export * from './privacy/privacy.component';
export * from './edit-order/edit-order.component';
export * from './single-service-details/single-service-details.component';
export * from './payment-abort/payment-abort.component';
export * from './payment-success/payment-success.component';
export * from './permissions/permissions.component';
