import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../models';
import { AuthenticationService } from '../../services';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})

export class TermsComponent implements OnInit {
  user: User;
  isLoggedIn: Observable<boolean>;

  constructor(private authenticationService: AuthenticationService) {}

  ngOnInit(): void {
    this.isLoggedIn = this.authenticationService.isLoggedIn();
  }
}
