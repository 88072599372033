import { Pipe } from '@angular/core';
import { MedicalTestCarouselItems } from '../models/medical-test.model';

@Pipe({
  name: 'carouselConfig'
})
export class CarouselConfigPipe {
  transform(config: any, categoryItems: MedicalTestCarouselItems) {
    const currentConfig = { ...config };
    const slidesToShow = 4;
    currentConfig.slidesToShow = (categoryItems.groupItems.length < config.slidesToShow) ?
      categoryItems.groupItems.length : config.slidesToShow;
    currentConfig.responsive.forEach((item: any) =>
      item.settings.slidesToShow = (categoryItems.groupItems.length < slidesToShow) ?
        categoryItems.groupItems.length : config.slidesToShow);
    return currentConfig;
  }
}
