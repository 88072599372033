<app-breadcrumbs></app-breadcrumbs>
<div class="section section-padding">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <h4>Your Cart:</h4>
        <div class="cart-container" *ngIf="cartItems.length > 0; else emptyCart">
          <ul class="list-group">
            <li class="list-group-item" *ngFor="let item of cartItems; let i = index">
              <div class="row cart-item">
                <div class="col-lg-6">
                  <div>{{ item.name }}</div>
                </div>
                <div class="col-lg-4">
                  <div>{{ item.price + ' UGX'}}</div>
                </div>
                <div class="col-lg-2">
                  <button class="btn btn-danger btn-sm" (click)="removeFromCart(item)">Remove</button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="sidebar style-10 mt-5 mt-lg-0">
          <div class="widget widget-booking">
            <h5 class="widget-title">Cart Summary</h5>
            <ul>
              <li class="d-flex align-items-center justify-content-between">
                <span>Total Cost</span>
                <span><b> {{ getTotalPrice() + ' UGX'}}</b></span>
              </li>
            </ul>
            <hr>
            <ul>
              <li class="d-flex align-items-center justify-content-between">
                <a (click)="checkout()" class="sigma_btn btn-block btn-sm mt-4">
                  Continue
                  <i class="fal fa-arrow-right ml-3"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="recommended-tests mt-4" *ngIf="recommendedMedicalTests.length > 0">
          <h5>Recommended Medical Tests:</h5>
          <div class="container medical-tests-section">
            <div class="profile-item">
              <div class="row ngx-slick-carousel-container">
                <ngx-slick-carousel
                class="profile-slider"
                [config]="slickCarouselConfig"
                >
                  <div ngxSlickItem *ngFor="let medicalTest of recommendedMedicalTests">
                    <img (click)="goToMedicalTestDetails(medicalTest)" [src]="medicalTest?.images?.[0]" alt="{{ medicalTest.name }}">
                    <div class="profile-inner">
                      <div class="duration-price-container">
                        <span>Duration: <b>{{ medicalTest.duration }}</b></span>
                        <span>{{ medicalTest.price }} UGX</span>
                      </div>
                      <a class="primary-color">
                        <h5 class="test-title">{{ medicalTest.name }}</h5>
                      </a>
                      <p class="pb-0">{{ medicalTest.description }}</p>
                      <a (click)="addToCart(medicalTest)" class="sigma_btn btn-link primary-color">Add to cart<i class="icofont-swoosh-right"></i></a>
                    </div>
                  </div>
                </ngx-slick-carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #emptyCart>
        <p>Your cart is empty.</p>
      </ng-template>
    </div>
  </div>
</div>
