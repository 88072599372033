import { Component, OnInit } from '@angular/core';
import { RoleService } from '../../services';
import { RoleName, Role, Permission } from '../../models';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
  adminRoles: RoleName[] = [
    RoleName.ADMIN_ONE,
    RoleName.ADMIN_TWO, 
    RoleName.ADMIN_THREE,
    RoleName.ADMIN_FOUR
  ];
  roles: Role[] = [];
  allPermissions: Permission[] = [];
  roleMapping: { [key in RoleName]?: string } = {
    [RoleName.ADMIN_ONE]: 'Super Admin',
    [RoleName.ADMIN_TWO]: 'Admin2',
    [RoleName.ADMIN_THREE]: 'Admin3',
    [RoleName.ADMIN_FOUR]: 'Admin4'
  };

  constructor(private roleService: RoleService) { }

  ngOnInit(): void {
    this.loadRoles();
  }

  loadRoles() {
    this.roleService.getRoles().subscribe((roles: Role[]) => {
      this.roles = roles.filter(role => 
        [RoleName.ADMIN_ONE, RoleName.ADMIN_TWO, RoleName.ADMIN_THREE, RoleName.ADMIN_FOUR].includes(role.name as RoleName)
      );
      this.loadAllPermissions();
    });
  }

  loadAllPermissions() {
    this.allPermissions = this.roles.reduce((permissions, role) => {
      role.permissions.map(permission => {
        if (!permissions.some(p => p.id === permission.id)) {
          permissions.push(permission); 
        }
      });
      return permissions;
    }, [] as Permission[]);
  }

  onRoleChange(event: Event, role: RoleName) {
    const selectElement = event.target as HTMLSelectElement;
    const permissionId = selectElement.value; 
    const roleToUpdate = this.roles.find(roleFound => roleFound.name === role);
    if (roleToUpdate) {
      this.updateRolePermissions(roleToUpdate, permissionId);
    }
  }

  updateRolePermissions(role: Role, permissionId: string) {
    const foundPermission = this.allPermissions.find(permission => permission.id.toString() === permissionId);
    if (!foundPermission) {
      console.error(`Permission with ID '${permissionId}' not found.`);
      return;
    }
    role.permissions = role.permissions.reduce((acc, p) => {
      return p.id === foundPermission.id ? acc : [...acc, p];
    }, role.permissions.some(permission => permission.id === foundPermission.id) ? [] : [foundPermission]);
    this.editRole(role);
  }
  
  editRole(role: Role) {
    const formData = new FormData();
    formData.append('permissionIds[]', role.permissions.map(item => item.id).toString());
    this.roleService.editRoles(role.id, formData).subscribe(
      response => {
        // nothing to add here
      },
      error => {
        console.error(`Failed to update permissions for role '${role.name}':`, error);
      }
    );
  }

  isSelected(role: RoleName, permissionId: string): boolean {
    return this.roles
      .find(roleFound => roleFound.name === role)
      ?.permissions.some(permission => permission.id.toString() === permissionId) ?? false;
  }  

  getRoleName(role: RoleName): string {
    return this.roleMapping[role] || 'Unknown';
  }

  removePermission(roleName: string, permissionId: string) {
    let role = this.roles.find(r => r.name === roleName);
    if (role) {
      const permissionExists = role.permissions.some(p => p.id.toString() === permissionId);
      if (permissionExists) {
        role.permissions = role.permissions.filter(p => p.id.toString() !== permissionId);
        this.editRole(role);
      } else {
        console.error(`Permission with ID '${permissionId}' not found in role '${roleName}'.`);
      }
    }
  }

}
