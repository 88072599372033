import { Component, OnInit } from '@angular/core';
import { CommonData } from '../../models';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  banner: CommonData[] = [];
  settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  };
  
  constructor() { }
  
  ngOnInit(): void {
    this.banner = [
      {
        icon: "assets/img/slider-1.jpeg",
        text: "Orders & Location",
        title: "Order For A <br> Test Near You."
      },
      {
        icon: "assets/img/slider-2.jpeg",
        text: "Tests & Results",
        title: "Get Your <br> Results Quickly."
      }
    ]
  
  }

}
