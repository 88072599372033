<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9">
  <div class="section">
    <div class="container">
      <form [formGroup]="settingsForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-12">
            <div class="sigma_form style-7">
              <div class="form-block">
                <h4>Settings:</h4>
                <h5>Change Password:</h5>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Current Password</label>
                      <i class="fal fa-key"></i>
                      <input 
                        type="password"  
                        class="form-control"
                        formControlName="currentPassword"
                      >
                      <div *ngIf="submitted && f.currentPassword.errors" class="is-invalid">
                        <div *ngIf="f.currentPassword.errors.required">Old password required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Confirm Current Password</label>
                      <i class="fal fa-key"></i>
                      <input 
                        type="password"  
                        class="form-control"
                        formControlName="confirmCurrentPassword"
                      >
                      <div *ngIf="submitted && (f.confirmCurrentPassword.invalid || settingsForm.hasError('isCurrentPasswordNotSame'))"
                        class="is-invalid">
                          Passwords should match
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>New Password</label>
                      <i 
                        (click)="hidePassword(true)"
                        [ngClass]="isPasswordHidden ? 'fal fa-eye' : 'fal fa-eye-slash'">
                      </i>
                      <input 
                        [type]="isPasswordHidden ? 'password' : 'text'"
                        class="form-control"
                        formControlName="newPassword"
                      >
                      <div *ngIf="submitted && f.newPassword.errors" class="is-invalid">
                        <div *ngIf="f.newPassword.errors.required">New password required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Confirm New Password</label>
                      <i 
                        (click)="hidePassword(true)"
                        [ngClass]="isPasswordHidden ? 'fal fa-eye' : 'fal fa-eye-slash'">
                      </i>
                      <input 
                        [type]="isPasswordHidden ? 'password' : 'text'"
                        class="form-control"
                        formControlName="confirmNewPassword"
                      >
                      <div *ngIf="submitted && (f.confirmNewPassword.invalid || settingsForm.hasError('isNewPasswordNotSame'))"
                        class="is-invalid">
                        Passwords should match
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <app-password-strength
                      [barLabel]="'Password Strength'"
                      [passwordToCheck]="f.newPassword.value"
                      (passwordStrong)="passwordStrong($event)"
                    ></app-password-strength>
                    <div class="is-invalid" *ngIf="submitted && !isPasswordStrong">Password not strong</div>
                  </div>
                </div>
                <div class="row">
                  <button type="submit" class="sigma_btn btn-sm mt-4">
                    Save
                    <i class="fal fa-arrow-right ml-3"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
