import { Component, OnInit } from '@angular/core';
import { UserProfileService, OrderService, DataService } from '../../services';
import { Counter } from '../../models';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnInit {
  counter: Counter[];
  totalUsers: number;
  totalOrders: number;

  constructor(
    private dataService: DataService,
    private userProfileService: UserProfileService,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
    this.counter = this.dataService.getCounter();
    this.orderService.getTotalOrders().pipe(
      mergeMap(totalOrders => {
        this.counter[1].value = totalOrders as number;
        return this.userProfileService.getTotalUsers();
      })).subscribe(totalUsers => {
        if (totalUsers) {
          this.counter[0].value = totalUsers as number;
        }
    });
  }

}
