import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { MedicalTest, Search } from '../models';

@Injectable({providedIn: 'root'})
export class MedicalTestService {
  constructor(
    private http: HttpClient,
  ) {}

  /**
   * Gets the medical tests
   * @return server response
   */
  getMedicalTests() {
    return this.http.get(
        'medical-tests')
        .pipe(map(response => response as MedicalTest[]));
  }

  /**
   * Filters the medical tests
   * @param searchCriteria
   * @return server response
   */
  getFilteredMedicalTests(searchCriteria: Search) {
    return this.http.post(
        'filter-medical-tests', searchCriteria)
        .pipe(map(response => response as MedicalTest[]));
  }

  /**
   * Post a medical-test
   *
   * @param formData
   */
  postMedicalTest(formData: any) {
    return this.http.post(
      'medical-test', formData)
      .pipe(map(response => response as MedicalTest));
  }

  /**
   * Updates the medical-test in the db
   *
   * @param medicalTestData to edit
   * @param id to test's id
   * @returns Observable<any>
   */
  editMedicalTest(id: number, medicalTestData: any) {
    return this.http.post(`medical-test/${id}`, medicalTestData);
  }

  /**
   * Deleted the medical-test from the db
   *
   * @param id to edit
   * @returns Observable<any>
   */
  deleteMedicalTest(id: number) {
    return this.http.delete(`medical-test/${id}`);
  }

  /**
   * sends ids in the cart 
   * @param formData cart medical test ids
   * @returns recommended medical tests
   */
  getRecommendedMedicalTests(formData: FormData) {
    return this.http.post(
      'recommended-medical-tests', formData)
      .pipe(map(response => response));
  }
}
