import { Component, OnInit } from '@angular/core';
import { FAQCategory, ContentItem } from '../../models';
import { DataService } from '../../services';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  faqs: FAQCategory[] = [];

  constructor (private dataService: DataService) {}

  ngOnInit(): void {
    this.faqs = this.dataService.getFaqs();
  }
}
