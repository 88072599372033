<fieldset class="form-group">
  <label>{{ textareaLabel }}</label>
  <textarea
    class="form-control"
    type="textarea"
    rows="{{ rows }}"
    [ngClass]="{'is-invalid': invalidInput}"
    [(ngModel)]="input"
    (ngModelChange)="onChange($event)"
    (blur)="onTouch()"
  ></textarea>
</fieldset>
