<app-breadcrumbs></app-breadcrumbs>
<div class="sidebar-style-9 service-details">
  <div class="section sigma_post-details">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="payment-notification">
            <div>
              <div class="container right">
                <div class="content">
                  <h4 class="order-title">Order created</h4>
                  <p>Your Payment has been successfully processed.</p>
                  <p class="text-muted">
                    <a
                      class="secondary-color font-weight-bold ml-1"
                      id="pills-home-tab"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                      (click)="goToOrder()"
                    >
                      View Order Progress
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

