import { Component, OnInit } from '@angular/core';
import { FilterDetails, MedicalTest, MedicalTestCarouselItems } from '../../models';
import { Router } from '@angular/router';
import { User } from '../../models/user.model';
import { MedicalTestService, DataService } from '../../services';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  user: User;
  filterIcons: FilterDetails[] = [];
  groupedMedicalTests: MedicalTestCarouselItems[];
  categoryNames: string[] = [];
  stateOfFilter: boolean[] = [];
  medicalTests: MedicalTest[];
  category1: MedicalTest[] = [];
  category2: MedicalTest[] = [];
  category3: MedicalTest[] = [];
  category4: MedicalTest[] = [];
  slickCarouselConfig = {
    infinite: true,
    lazyLoad: 'ondemand',
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1024,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  constructor(
    private router: Router,
    private medicalTestService: MedicalTestService,
    private dataService: DataService
  ) {}

  ngOnInit() {
    if (this.medicalTestService.getMedicalTests()) {
      this.medicalTestService.getMedicalTests().subscribe(
        medicalTests => {
          this.groupedMedicalTests = this.getGroupedData(medicalTests as MedicalTest[]);
          //TODO: Assign tests to respective categories as a temporary solution 
          this.category1 = this.filterTestsByCategory('Basic Health Checkups');
          this.category2 = this.filterTestsByCategory('Routine Blood Tests');
          this.category3 = this.filterTestsByCategory('Infectious Diseases');
          this.category4 = this.filterTestsByCategory('Nutritional and Metabolic Panels');
        });
    }
  }


  getGroupedData(medicalTests: MedicalTest[]) {
    return medicalTests.reduce(
      (total: MedicalTestCarouselItems[], { category }) => {
        if (!total.some(element => element.category === category)) {
          total.push({ category, groupItems: medicalTests.filter(element => element.category === category) });
        }
        return total;
      }, []);
  }

  filterTestsByCategory(category: string): MedicalTest[] {
    return this.groupedMedicalTests?.find(group => group.category === category)?.groupItems || [];
  }

  goToMedicalTestDetails(medicalTest: MedicalTest) {
    this.dataService.saveDataToView(medicalTest);
    this.router.navigate(['medical-test-details']);
  }
}
