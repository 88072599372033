import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { User } from '../../models';
import { AuthenticationService, SendMailService } from '../../services';
import {first} from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  submitted = false;
  contactForm: FormGroup;
  user: User;
  isUser: boolean;
  subject: string;

  constructor (
    private formBuilder: FormBuilder,
    private sendMailService: SendMailService,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.subject = history.state.data;
    this.contactForm = this.formBuilder.group({
      fullname: ['', [Validators.required]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      telephone: ['', Validators.required],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
    this.authenticationService.isLoggedIn().subscribe(isUser => {
      if (isUser) {
        this.user = this.authenticationService.currentUser()['user'];
        this.f.fullname.setValue(this.user.firstname +  ' ' + this.user.lastname);
        this.f.email.setValue(this.user.email);
        this.f.telephone.setValue(this.user.telephone);
        this.f.subject.setValue(this.subject);
        this.f.message.setValue(this.subject ?
            `Could you please send me more information about the ${this.subject}` : '');
      }
    });
  }

  get f() {
    return this.contactForm.controls;
  }

  onSubmit() {
    const fullName = this.contactForm.get('fullname')?.value || '';
    const [firstname, lastname] = fullName.split(' ');
    const formData = {
      ...this.contactForm.value,
      firstname: firstname,
      lastname: lastname
    };
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }
    this.sendMailService.sendInquiry(formData)
      .pipe(first()).subscribe(response => {
        if (response['message'] === 'success') {
          Swal.fire({
            title: 'Message sent!',
            text: 'We shall get back to you shortly',
            icon: 'success',
            confirmButtonColor: '#38a745',
          }).then(() => {
            this.router.navigate(['/']);
          });
        }
    },error => {
      Swal.fire({
        title: 'An error occurred',
        html: 'Please try again later',
        icon: 'error',
        confirmButtonColor: '#f37b7b',
      });
    });
  }
}
