import {
  Component,
  Input,
  ViewChild,
  OnInit,
  ElementRef,
  EventEmitter,
  Output, forwardRef
} from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    }
  ]
})
export class SelectComponent implements OnInit {
  @Input() showLabel: boolean;
  @Input() selectLabel: string;
  @Input() multipleType: object;
  @Input() selectTexts: any[];
  @Input() selectHeightClass: string;
  @Input() form: FormGroup;
  @Input() invalidInput: any;
  @ViewChild('select', {static: true}) select: ElementRef;
  @Output() valueChangedEvent = new EventEmitter<any>();
  input: string;

  constructor() {}

  ngOnInit() {
    this.select.nativeElement.addEventListener('change', () => {
      this.valueChangedEvent.emit(this.select.nativeElement.value);
    });
  }

  onChange: any = () => {};
  onTouch: any = () => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(input: string) {
    this.input = input;
  }
}
