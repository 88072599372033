<div class="section section-padding sigma_service-sec style-16">
  <div class="container">
    <div class="row category-reset-container">
      <div>Categories</div>
      <div (click)="populateMedicalTests()">
        <i class="fa fa-undo-alt" aria-hidden="true"></i>
        Refresh
      </div>
    </div>
    <div class="filter-container medical-tests-section">
      <ng-container>
        <div class="profile-item">
          <div class="row ngx-slick-carousel-container filters">
            <ngx-slick-carousel
              class="profile-slider"
              [config]="slickCarouselConfig">
              <div ngxSlickItem *ngFor="let item of filterIcons; let i = index;">
                <div
                  class="sigma_service style-16"
                  [class.is-filter]="stateOfFilter?.[i]"
                  (click)="filterMedicalTests({category: item.title}, i);">
                  <div class="sigma_service-thumb"><i [class]="item.icon"></i></div>
                  <div class="sigma_service-body"><h5>{{item.title}}</h5></div>
                </div>
              </div>
            </ngx-slick-carousel>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="sigma_service-sec">
      <div class="container medical-tests-section">
        <ng-container *ngIf="medicalTests.length > 0">
          <div class="profile-item">
            <div class="row">
              <div 
                class="medical-test-col" 
                *ngFor="let medicalTest of medicalTests | paginate: { itemsPerPage: 8, currentPage: page }"
              >
                <img (click)="goToMedicalTestDetails(medicalTest)" [src]="medicalTest?.images?.[0]" alt="{{ medicalTest.name }}">
                <div class="profile-inner">
                  <!-- TODO: add if tests can be advertised -->
                  <!-- <span class="featured" *ngIf="test.is_featured">FEATURED</span> -->
                  <div class="duration-price-container">
                    <span>Duration: <b>{{ medicalTest.duration }}</b></span>
                    <span>{{ medicalTest.price }} UGX</span>
                  </div>
                  <a (click)="goToMedicalTestDetails(medicalTest)" class="primary-color">
                    <h5 class="test-title">{{ medicalTest.name }}</h5>
                  </a>
                  <p class="pb-0">{{ medicalTest.description }}</p>
                  <!-- <a class="btn-link primary-color" href="/medical-test-details"> View <i
                      class="icofont-swoosh-right"></i>
                    </a> -->
                  <button class="sigma_btn btn-block btn-sm mb-3" (click)="addToCart(medicalTest)">
                    {{ cartItems | buttonText: medicalTest }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Pagination -->
          <pagination-controls (pageChange)="page = $event" [responsive]="isResponsive" previousLabel="<" nextLabel=">">
          </pagination-controls>
          <!-- Pagination -->
        </ng-container>
        <div *ngIf="medicalTests.length === 0" class="no-medical-tests-found">No medical tests found</div>
      </div>
    </div>
  </div>
</div>
