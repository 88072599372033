import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../services';
import { Role, RoleName } from '../../models';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm: FormGroup;
  submitted: boolean = false;
  returnUrl: string;
  isExpired: boolean;
  error = '';
  isPasswordHidden: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterViewInit() {
    document.body.classList.add('authentication-bg');
    document.body.classList.add('authentication-bg-pattern');
  }

  get f() { return this.loginForm.controls; }

  hidePassword() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  /**
   * On submit form
   */
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
        .subscribe(
          data => {
            if (data.message === 'Not verified') {
              this.error = 'Account not verified yet';
              // TODO: should we notify the user that they need to verify their account?
            } else {
                if (!data.user.role) {
                  if (this.returnUrl !== '/') {
                    this.router.navigate([this.returnUrl]);
                  } else {
                    this.router.navigate(['dashboard']); 
                  }
                } else if (data.user.role && this.authenticationService.isAdminSubject.value) {
                  this.router.navigate(['admin']); 
                }
            }
          }, error => {
            Swal.fire({
              title: 'An error occurred',
              text: 'email or password invalid',
              icon: 'error',
              confirmButtonColor: '#f37b7b',
            });
          });
  }
}
