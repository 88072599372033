import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Cart, MedicalTest } from '../models';
import { map } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class CartService {
  headers: HttpHeaders = new HttpHeaders({ 'X-Requested-With': 'XMLHttpRequest' });
  cartMedicalTestsSubject = new BehaviorSubject<MedicalTest[]>([]);

  constructor(private http: HttpClient) {}

  /**
   * Gets the user' cart details
   * @return server response
   */
  getUserCart(userId: number) {
    return this.http.get(
        `user-cart/${userId}`)
        .pipe(map(response => response as Cart | any));
  }

  /**
   * Adds item to cart
   * @param formData
   */
  createCart(formData: FormData) { 
    return this.http.post(
      'cart', formData)
      .pipe(map(response => response));
  }

  /**
   * Updates cart item
   * @param userId
   * @param formData
   */
  updateCart(userId: number, formData: FormData) {
    return this.http.post(`cart/${userId}`, formData);
  }

  /**
   * Saves the cart items
   * @param medicalTests selected
   */
  saveToCart(medicalTests: MedicalTest[]) {
    const currentCart = [...this.cartMedicalTestsSubject.getValue()
        .filter(item => !medicalTests.some(medicalTest => medicalTest.id === item.id)), ...medicalTests];
    this.cartMedicalTestsSubject.next(currentCart);
  }

  /**
   * Removes the selected cart items
   * @param medicalTests selected
   */
  removeFromCart(medicalTests: MedicalTest) {
    const currentCart = this.cartMedicalTestsSubject.getValue();
    const updatedCart = currentCart.filter(item => item.id !== medicalTests.id);
    this.cartMedicalTestsSubject.next(updatedCart);
  }

  /**
   * Gets the select cart items
   * @return Observable of array of MedicalTest
   */
  getCartMedicalTests() {
    return this.cartMedicalTestsSubject.asObservable();
  }
}
